import Vue from 'vue';
import injector from 'vue-inject';
import VueLogger from 'vuejs-logger';
import VeeValidate, { Validator } from 'vee-validate';
import es from 'vee-validate/dist/locale/es';
import VueI18n from 'vue-i18n';
import VueRouterUserRoles from 'vue-router-user-roles';
import VueWait from 'vue-wait';
import VueSweetalert2 from 'vue-sweetalert2';
import VueTheMask from 'vue-the-mask';
import VueElementLoading from 'vue-element-loading';
import VueSimpleSpinner from 'vue-simple-spinner';
import numerify from 'numerify';
import VueAnalytics from 'vue-analytics';
import vSelect from 'vue-select';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCloudUploadAlt, faCheckCircle, faInfoCircle, faChevronLeft, faChevronRight, faDownload, faArrowRight, faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Components
import './components';

import App from './App.vue';
import router from './router';
import store from './store';
import messages from './translations';

library.add(faCloudUploadAlt);
library.add(faCheckCircle);
library.add(faInfoCircle);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faDownload);

require('./app_start');

Vue.use(injector);
Vue.use(VueLogger, { showConsoleColors: true });
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
});
Vue.use(VueI18n);
Vue.use(VueRouterUserRoles, { router });
Vue.use(VueWait);
Vue.use(VueSweetalert2);
Vue.use(VueTheMask);
Vue.use(VueAnalytics, {
  id: 'UA-142962118-1',
  router,
  debug: {
    enabled: false,
  },
});
Vue.component('v-select', vSelect);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('VueElementLoading', VueElementLoading);
Vue.component('VueSimpleSpinner', VueSimpleSpinner);

Validator.localize('es', es);

const i18n = new VueI18n({
  locale: 'es',
  messages,
});

Vue.filter('currency', (value, symbol) => {
  if (value !== 0 && !value) return '';
  symbol = symbol === 'USD' ? '$' : 'S/';
  return `${symbol} ${numerify(value, '0,0.00')}`;
});

Vue.filter('percentage', (value) => {
  if (value !== 0 && !value) return '';
  return `${numerify(value, '0,0.00')}%`;
});

Vue.config.productionTip = false;

require('bootstrap');
// eslint-disable-next-line import/no-extraneous-dependencies
require('sweetalert2/dist/sweetalert2.min.css');

const authenticate = Promise.resolve({ role: 'GUEST' });

authenticate.then((user) => {
  Vue.prototype.$user.set(user);
  new Vue({
    router,
    store,
    i18n,
    wait: new VueWait({
      useVuex: true,
      vuexModuleName: 'wait',
    }),
    render: (h) => h(App),
  }).$mount('#app');
});
