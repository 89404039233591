<template lang="pug">
  .form-group.custom-control.custom-checkbox
    input.custom-control-input(
      :id='id'
      :name='name'
      type='checkbox'
      autocomplete='off'
      :aria-describedby='`${id}Help`'
      :checked='checked'
      @change='$emit("change", $event.target.checked)'
      :class='{"is-invalid": error}')
    label.custom-control-label(:for='id')
      slot
    small.form-text.text-muted(
      :id='`${id}Help`'
      v-if='helper'
      v-html='helper')
    .invalid-tooltip(v-if='error' v-text='error')
</template>

<script>
export default {
  name: 'KCheckbox',
  inheritAttrs: false,
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.checked;
    },
    rejectsFalse: true,
    validator: 'new',
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: Boolean,
    name: String,
    label: String,
    error: String,
    helper: String,
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
  },
};
</script>
