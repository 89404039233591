import Vue from 'vue';
import Vuex from 'vuex';

import global from './modules/global';
import alert from './modules/alert';
import solicitud from './modules/solicitud';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    global,
    alert,
    solicitud,
  },
  strict: debug,
  plugins: [
  ],
});
