<template lang="pug">
  #root.container-fluid(:class='{ [bg]: true }')
    .row
      .col.p-0
        k-header
        #views.container-fluid
          .row
            .col.p-0
              k-stepper(
                v-if='["VisualizacionModelo", "Dictamen", "Dictamen2", "Fin", "NoPodemosAtenderte", "UnEjecutivoContactara", "Proforma"].indexOf(view) === -1'
                :steps='steps'
                :step='step')
              component(:is='view')
          .row.footerxs
            .col.footerxs
              k-footer(v-if='["NoPodemosAtenderte", "UnEjecutivoContactara"].indexOf(view) === -1')
          //- .row.h-100
          //-   .col
          //-     k-footer
</template>

<script>
import { mapGetters } from 'vuex';
import solicitudTypes from '@/store/types/solicitud';

import ValidacionInicial from '@/views/ValidacionInicial.vue';
import DatosPersonales from '@/views/DatosPersonales.vue';
import InformacionCotizacion from '@/views/InformacionCotizacion.vue';
import VisualizacionModelo from '@/views/VisualizacionModelo.vue';
import DatosLaborales from '@/views/DatosLaborales.vue';
import VisitaConcesionario from '@/views/VisitaConcesionario.vue';
import Ingresos from '@/views/Ingresos.vue';
import Dictamen from '@/views/Dictamen.vue';
import Dictamen2 from '@/views/Dictamen2.vue';
import Pin from '@/views/Pin.vue';
import PreDocumentos from '@/views/PreDocumentos.vue';
import Documentos from '@/views/Documentos.vue';
import Fin from '@/views/Fin.vue';
import NoPodemosAtenderte from '@/views/NoPodemosAtenderte.vue';
import UnEjecutivoContactara from '@/views/UnEjecutivoContactara.vue';
import Proforma from '@/views/Proforma.vue';

export default {
  name: 'Root',
  components: {
    ValidacionInicial,
    DatosPersonales,
    InformacionCotizacion,
    VisualizacionModelo,
    DatosLaborales,
    VisitaConcesionario,
    Ingresos,
    Dictamen,
    Dictamen2,
    Pin,
    PreDocumentos,
    Documentos,
    Fin,
    NoPodemosAtenderte,
    UnEjecutivoContactara,
    Proforma,
  },
  computed: {
    ...mapGetters({
      view: solicitudTypes.getters.getView,
    }),
    steps() {
      return [
        'Datos Personales',
        'Datos Laborales',
        'Datos de Financiamiento',
        'Datos Complementarios',
      ];
    },
    step() {
      switch (this.view) {
        case 'ValidacionInicial': // LISTO LISTO
        case 'InformacionCotizacion': // LISTO LISTO
        case 'DatosPersonales': // LISTO LISTO
          return 0;
        case 'VisualizacionModelo': // no existe en rebranding
        case 'DatosLaborales': // LISTO LISTO
          return 1;
        case 'Ingresos': // LISTO LISTO
        case 'Dictamen': // LISTO LISTO
        case 'Proforma': // LISTO LISTO
          return 2;
        case 'Pin': // LISTO LISTO
          return 3;
        case 'Documentos': // LISTO LISTO
          return 4;
        case 'PreDocumentos': // LISTO LISTO
          return 5;
        case 'VisitaConcesionario': // LISTO LISTO
          return 6;
        default:
          return 10;
      }
    },
    bg() {
      switch (this.view) {
        case 'ValidacionInicial':
        case 'DatosPersonales':
          return ''; // 'type01';
        case 'InformacionCotizacion':
          return ''; // 'type02';
        case 'VisualizacionModelo':
          return ''; // 'type02_1';
        case 'DatosLaborales':
          return ''; // 'type03';
        case 'Dictamen':
          return ''; // 'type04';
        case 'Dictamen2':
          return ''; // 'type08';
        case 'Pin':
        case 'Documentos':
          return ''; // 'type05';
        case 'VisitaConcesionario':
          return ''; // 'type06';
        case 'Proforma':
        case 'Fin':
          return ''; // 'type07';
        case 'UnEjecutivoContactara':
          return ''; // 'type09';
        default:
          return 'empty';
      }
    },
  },
};
</script>

<style lang="scss">
  #root {
    // height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #views {
    height: calc(98% - 85.6px);
  }
  .type01 {
    // background-image: url('../../assets/bg/Group 226.png');\
    background-color: #fff;
    background-position: top;
  }
  .type02_1 {
    background-image: url('../../assets/bg/Group 231.png');
    height: 100%;
    background-position: top;
  }
  .type03 {
    background-image: url('../../assets/bg/Group 232.png');
    background-position: top;
    height: 100%;
  }
  .type04 {
    background-color: #fff;
    background-position: top;
    height: 100%;
  }
  .type05 {
    background-image: url('../../assets/bg/Group 229.png');
    background-position: top;
    height: 100%;
  }
  .type06 {
    background-image: url('../../assets/bg/Group 233.png');
    background-position: top;
    height: 100vh;
  }
  .type07 {
    background-color: #fff;
    background-position: top;
    height: 100%;
  }
  .type08 {
    background-color: #fff;
    background-position: top;
    height: 100%;
  }
  .type09 {
    background-image: url('../../assets/bg/UnEjecutivoContactara.png');
    background-position: top;
    height: 100vh;
  }

.kontigo__copyright {
  margin-top: 15px;
}

/* .kontigo__copyright:last-child {
  margin-top: 80px;
} */

@media only screen and (max-width:620px) {
  .footerxs{
    display: none;
    visibility:hidden !important;
    max-height: 0px !important;
  }
  .type02_1 {
    background-image: url('../../assets/bg/Group 231.png');
    height: 100%;
  }
  .type03 {
    background-image: url('../../assets/bg/Group 232.png');
    height: 100%;
  }
  .type04 {
    background-color: #fff;
    background-position: top;
    height: 100%;
  }
  .type05 {
    background-image: url('../../assets/bg/Group 229.png');
    height: 100%;
  }
  .type06 {
    background-image: url('../../assets/bg/Group 233.png');
    height: 100;
  }
  .type07 {
    background-color: #fff;
    background-position: top;
    height: 100%;
  }
  .type08 {
    background-color: #fff;
    background-position: top;
    height: 100%;
  }
  .type09 {
    background-image: url('../../assets/bg/UnEjecutivoContactara.png');
    height: 100vh;
  }
}
@media only screen and (max-width:480px) {
  .type02_1 {
    background-image: url('../../assets/bg/Group 231.png');
    height: 100%;
  }
  .type03 {
    background-image: url('../../assets/bg/Group 232.png');
    height: 100%;
    background-position: 65%;
  }
  .type04 {
    background-color: #fff;
    background-position: top;
    height: 100%;
  }
  .type05 {
    background-image: url('../../assets/bg/Group 229.png');
    height: 100%;
  }
  .type06 {
    background-image: url('../../assets/bg/Group 233.png');
    height: 100%;
  }
  .type07 {
    background-color: #fff;
    background-position: top;
    height: 100%;
  }
  .type09 {
    background-image: url('../../assets/bg/UnEjecutivoContactara.png');
    height: 100%;
  }
  .type07 {
    background-color: #fff;
    background-position: top;
    height: 100%;
  }
  .type08 {
    background-color: #fff;
    background-position: top;
    height: 100%;
  }
  .type09 {
    background-image: url('../../assets/bg/UnEjecutivoContactara.png');
    height: 100vh;
  }
}
</style>
