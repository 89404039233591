<template lang="pug">
  .container
    .row.text-center.k-smv
      .col.p-0
        img(src='@/assets/bg/NoPodemosAtenderte_mobile.png' width="100%")
    .row.text-center.k-smv
      .col.bg-primary.pt-5
        .container.text-white.text-left.h-100
          .row.h-100
            .col-10.offset-1.d-flex.align-items-center
              div
                h6 Hola {{ firstName }},
                p lo sentimos, por el momento no podemos atenderte a trav&eacute;s de este canal.
                p.mute Te invitamos a visitar nuestra p&aacute;gina web para que te informes sobre otros productos.
                form(
                  novalidate=''
                  @submit.prevent='onSubmit'
                  data-vv-scope='form1')
                  br
                  br
                  br
                  .pt-3
                    .row
                      .col-12.col-sm-12.offset-sm-12.order-2.order-xs-1.text-left
                        button.btn.btn-success.btn-md.btn-xs-block(
                          type='submit' href='javascript:void(0)' @click='close') Cerrar
    .row.text-center.k-swv
      .col-4.offset-2.bg-primary.p-0
        .container.text-white.text-left.h-100
          .row.h-100
            .col-10.offset-1.d-flex.align-items-center
              div
                h6 Hola {{ firstName }},
                p lo sentimos, por el momento no podemos atenderte a trav&eacute;s de este canal.
                p.mute Te invitamos a visitar nuestra p&aacute;gina web para que te informes sobre otros productos.
                form(
                  novalidate=''
                  @submit.prevent='onSubmit'
                  data-vv-scope='form1')
                  br
                  br
                  br
                  .pt-3
                    .row
                      .col-12.col-sm-12.offset-sm-12.order-2.order-xs-1.text-left
                        button.btn.btn-success.btn-md.btn-xs-block(
                          type='submit' href='javascript:void(0)' @click='close') Cerrar
      .col-4.p-0
        img.img-fluid(src='@/assets/bg/NoPodemosAtenderte.png')
</template>

<script>
import { mapGetters } from 'vuex';
import solicitudTypes from '@/store/types/solicitud';

export default {
  name: 'NoPodemosAtenderte',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        tipoProductoId: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      firstName: solicitudTypes.getters.getFirstName,
    }),
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getFin();
        if (data) {
          this.CommonService.assign(this.form, data);
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async onSubmit() {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        // try {
        //   this.$wait.start('global');
        //   await this.SolicitudService.putFin({});
        //   this.$store.commit(solicitudTypes.mutations.logout);
        // } catch (err) {
        //   this.$swal({ type: err.type, text: err.message });
        // } finally {
        //   this.$wait.end('global');
        // }
      }
    },
    close() {
      window.close();
    },
  },
};
</script>

<style lang="scss" scoped="">
  .mute {
    font-size: 0.7rem;
  }
  .k-smv {
    display: none;
    visibility: hidden;
  }
  @media only screen and (max-width:620px) {
    .k-smv {
      display: block;
      visibility: visible;
    }
    .k-swv {
      display: none;
      visibility: hidden;
    }
  }
</style>
