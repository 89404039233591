<template lang="pug">
  .h-100.d-flex.align-items-end.containerxsDictamen(style="background-color: #EB0A1E")
    .w-100
      .row(style="display: flex; justify-content: center;")
        .col-12.bg-white.m-5(style="max-width: 90%;border-radius: 4px")
          div.col-8.offset-1
            div.row
              div.col-3.p-0.mt-4.text-right
                <img class="img_size2" src="@/assets/images/icon_crdito-preaprobado.svg" alt="" />
              div.col-9.p-0.pt-3.text-left
                h5.text-white.text-center.card-title
                  strong Hola {{ firstName }},
                h5.text-white.text-center.card-title2
                  | por el momento aplicas para las siguientes opciones de financiamiento.
                  //- br
                  //- |  opciones de financiamiento.
                br
                br
          form(
            novalidate=''
            @submit.prevent='onSubmit(null)'
            data-vv-scope='form1')
              .justify-content-center.text-white
                .row.text-center.mb-4.justify-content-center(v-for='option in form.evaluacionCredito.options')
                  .col-12.col-lg-4
                    ul.list-unstyled.mt-3.mb-4
                      div.col.m-auto(style="max-width: 480px")
                        div.row.text-right.cuoteDictum.py-2.mt-3
                          div.col-2.p-0.pt-0.m-auto
                            <img class="img_size3" src="@/assets/icons/icon-check-circle.svg" alt="" />
                          div.col-10.p-0.text-center
                            span.text-plomo.card-title2.m-0 &nbsp;Cuota mensual de: {{ option.ValorCuota | currency(form.monedaId) }}
                      br
                      span.cuota.text-plomo.tceaDictum {{ option.NumeroCuotas - 1 }} meses TCEA {{ option.TCEA | percentage }}
                    span.mt-4.text-center.k-cursor-pointer.text-primary(@click='showModal = true' style="text-decoration: underline #EB0A1E; font-family: 'BebasNeuePro-Bold' !important") Ver términos y condiciones
                    br
                    br
                    button.btn-choose.btn.btn-success.btn-xs-block.claseboton(
                      @click='onSubmit(option.numeroSolicitud)'
                      type='button'
                      style="max-width: 330px") {{ $t('dictamen.navigation.submit') }}
                  k-modal(
                    v-if='showModal'
                    @close='showModal = false').text-justify
                    p.text-body {{ modalText }}
</template>

<script>
import { mapGetters } from 'vuex';
import solicitudTypes from '@/store/types/solicitud';
import moment from 'moment-timezone';

export default {
  name: 'Dictamen',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        modeloDescripcion: null,
        montoVehiculoDolares: null,
        montoVehiculoSoles: null,
        monedaId: null,
        evaluacionCredito: {
          options: [],
        },
        toyotaValueSoles: null,
        toyotaValueDolares: null,
      },
      showModal: false,
      selectedOption: null,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters({
      firstName: solicitudTypes.getters.getFirstName,
    }),
    modalText() {
      if (this.selectedOption) {
        const {
          MonedaCredito,
          PorcentajeBalon,
          NumeroCuotas,
          ValorCuota,
          TipoCambio,
          Porcentaje,
          TEA,
          TCEA,
          BonoDolares,
          MontoSeguro,
        } = this.selectedOption;

        let bonoUSD = 0;
        let bonoPEN = 0;
        let valCuotaUSD = 0;
        let valCuotaPEN = 0;
        let MontoSeguroUSD = 0;
        let MontoSeguroPEN = 0;
        let bonoTexto = '';
        // let MontoGpsUSD = 0;
        // let MontoGpsPEN = 0;

        if (MonedaCredito === 'DOLARES') {
          bonoUSD = BonoDolares;
          bonoPEN = (BonoDolares * TipoCambio).toFixed(2);
          valCuotaUSD = ValorCuota;
          valCuotaPEN = (ValorCuota * TipoCambio).toFixed(2);
          MontoSeguroUSD = MontoSeguro;
          MontoSeguroPEN = (MontoSeguro * TipoCambio).toFixed(2);
          // MontoGpsUSD = 0;
          // MontoGpsPEN = 0;
        } else {
          bonoUSD = BonoDolares;
          bonoPEN = (BonoDolares * TipoCambio).toFixed(2);
          valCuotaUSD = (ValorCuota / TipoCambio).toFixed(2);
          valCuotaPEN = ValorCuota;
          MontoSeguroUSD = (MontoSeguro / TipoCambio).toFixed(2);
          MontoSeguroPEN = MontoSeguro;
          // MontoGpsUSD = 0;
          // MontoGpsPEN = 0;
        }
        // const montoSolicitado = monedaId === 'USD' ? montoVehiculoDolares : montoVehiculoSoles
        const cuoInicialDolares = (((this.form.montoVehiculoDolares - bonoUSD) * Porcentaje) / 100).toFixed(2);
        const cuoInicialSoles = (((this.form.montoVehiculoSoles - bonoPEN) * Porcentaje) / 100).toFixed(2);
        const cuoFinalDolares = (((this.form.montoVehiculoDolares - bonoUSD) * PorcentajeBalon) / 100).toFixed(2);
        const cuoFinalSoles = (((this.form.montoVehiculoSoles - bonoPEN) * PorcentajeBalon) / 100).toFixed(2);

        if (bonoUSD > 0) {
          bonoTexto = `, el precio referencial del
                vehículo incluye el bono de descuento que se otorga por la compra del vehículo de hasta
                $ ${bonoUSD} o S/ ${bonoPEN}. El cliente aplicar\u00E1 el bono solo al precio del
                vehículo`;
        }

        const hastv = this.form.toyotaValueSoles && this.form.toyotaValueSoles > 0;

        const strtv = `El Paquete Toyota Value, lo otorga MAF Perú, previa aprobación del Plan Toyota Life según política crediticia de MAF,
                incluye los mantenimientos prepagados hasta los 20,000km alineados a su libreta de garantía. Asimismo, cabe resaltar
                que la disponibilidad de este paquete, beneficios adicionales, así como los términos y condiciones aplicables pueden
                variar según el Concesionario, modelo y versión. Consulte con su concesionario de preferencia respecto a la
                disponibilidad y beneficios del paquete Toyota Value para cada modelo.`;

        return `"El plan Toyota Life lo otorga MAF previa evaluaci\u00F3n y aprobaci\u00F3n crediticia para el modelo
                ${this.form.modeloDescripcion}. S\u00F3lo uso particular del vehículo. Año de fabricaci\u00F3n ${moment().year()}. La Pre
                Aprobaci\u00F3n y las Condiciones Referenciales del Cr\u00E9dito tienen vigencia durante 15 días
                calendario contados desde ${moment().format('DD/MM/YYYY')} hasta ${moment().add(15, 'days').format('DD/MM/YYYY')}
                de ${moment().year()}. Precio referencial del ${this.form.modeloDescripcion} S/
                ${this.form.montoVehiculoSoles} o $ ${this.form.montoVehiculoDolares}${bonoTexto}, cuota inicial ${Porcentaje}%
                equivalente a $ ${cuoInicialDolares} o S/ ${cuoInicialSoles}, ${NumeroCuotas - 1} cuotas mensuales
                de $ ${valCuotaUSD} o S/ ${valCuotaPEN}, y una cuota final N° ${NumeroCuotas} de ${PorcentajeBalon}% del valor del vehículo
                por un monto de $ ${cuoFinalDolares} o S/ ${cuoFinalSoles}, incluye Seguro Toyota; monto a financiar
                $ ${MontoSeguroUSD} o S/ ${MontoSeguroPEN}, incluye el costo del ${hastv ? 'Toyota Value, GPS' : 'GPS'}, seguro de
                desgravamen, gastos notariales y registrales. TEA referencial: ${TEA}%, TCEA referencial: de ${TCEA}%.
                Tipo de cambio referencial S/ ${TipoCambio}, al momento de la transacci\u00F3n se aplicar\u00E1 el tipo de cambio
                vigente del día. Para mayor informaci\u00F3n de condiciones y requisitos del plan Toyota Life y condiciones de
                acceso a bono otorgado por MAF, tasas de inter\u00E9s, comisiones y gastos estar\u00E1n disponibles en
                el tarifario en oficinas de atenci\u00F3n y en www.mafperu.com. Informaci\u00F3n difundida de acuerdo a
                ley N° 28587 y Res SBS N° 3274-2017. No acumulable con otras promociones. Aplica a nivel
                nacional.
                Las características y condiciones antes descritas son únicamente referenciales, se encuentran
                sujetas a la entrega posterior de la documentaci\u00F3n declarada y/o adicional a MAF; y, a la previa
                evaluaci\u00F3n crediticia y aprobaci\u00F3n finales de MAF. Para tales efectos, agradeceremos descargue
                la carta de pre aprobaci\u00F3n y se acerque a cualquiera de nuestras oficinas especiales, en los
                distintos puntos a nivel nacional.
                ${hastv ? strtv : ''}"`;
      }
      return '';
    },
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getDictamen();
        if (data) {
          this.CommonService.assign(this.form, data);
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async onSubmit(numeroSolicitud) {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        try {
          this.$wait.start('global');
          const result = await this.SolicitudService.putDictamen({ numeroSolicitud });
          this.$store.commit(solicitudTypes.mutations.setView, result.view);
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      }
    },
  },
};
</script>

<style scoped="">
  .btn-choose {
    min-width: 13rem;
    padding: 0.5rem;
  }
  h1.cuota {
    font-size: 3rem;
  }
  small.cuota {
    font-size: 1rem;
  }

  @media only screen and (max-width:620px) {
    h1.congratz {
      font-size: 2.2rem;
    }

    h2 br {
      display: none;
    }
    .containerxsDictamen{
      margin-top: 0px !important;
      margin-bottom: 80px;
    }
  }
  .imagenFondo2{
  background-image: url('../assets/bg/img_creditopreaprobado.png');
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 50vw;
}
.imagenFondo{
  background-image: url('../assets/bg/img_creditopreaprobado.png');
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 50vw;
}
.card-title{
  font-size: 30px !important;
  color: #EB0A1E !important;
  font-family: 'BebasNeuePro-Bold';
  margin-bottom: 0px;
}
.card-title2{
  font-size: 24px !important;
  color: #4A4A4A !important;
  font-family: 'BebasNeuePro-Bold';
}
.img_size2{
  /* max-height: 100%; */
  width: 100%;
  max-width: 85px;
}
.img_size3{
  /* max-height: 100%; */
  width: 38px;
  max-width: 60px;
}
.cuoteDictum{
  border-radius: 3px 25px 3px 3px;
  background-color: #E9EAE8;
  margin: auto;
}
.infoDictum{
  font-size: 16px;
  font-family: 'BebasNeuePro-Bold';
  color: #616161 !important;
}
.tceaDictum{
  margin-top: 0px;
  font-size: 14px;
  font-family: 'BebasNeuePro';
  color: #616161 !important;
}
.cartaPreAprobacion{
  border-radius: 3px 25px 3px 3px;
  background-color: #E9EAE8;
  margin: auto;
  font-size: 16px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border: solid #4A4A4A 1px;
  font-family: 'BebasNeuePro-Bold';
  color:#4A4A4A;
}
.claseboton{
  border-radius: 3px 25px 3px 3px;
  height: 50px;
}
</style>
