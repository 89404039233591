import namespace from '@/store/namespace';

export default namespace('global', {
  getters: [
    'isProduction',
  ],
  mutations: [
  ],
  actions: [
  ],
});
