import namespace from '@/store/namespace';

export default namespace('alert', {
  getters: [
  ],
  mutations: [
    'success',
    'warning',
    'error',
    'clear',
  ],
  actions: [
    'success',
    'warning',
    'error',
    'clear',
  ],
});
