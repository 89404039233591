import Vue from 'vue';
import Router from 'vue-router';

import Root from '@/views/Layout/Root.vue';
import Home from '@/views/Home.vue';
import Error400 from '@/views/Error400.vue';
import Error404 from '@/views/Error404.vue';
import Atendido from '@/views/Atendido.vue';

Vue.use(Router);

/* eslint-disable */
function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}
/* eslint-enable */

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes: [
    {
      path: '/',
      component: Root,
      children: [
        {
          path: '',
          name: 'home',
          component: Home,
          meta: {
            permissions: [
              {
                role: 'GUEST',
                access: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: '/error',
      component: Error400,
    },
    {
      path: '/error404',
      component: Error404,
    },
    {
      path: '/atendido',
      component: Atendido,
    },
  ],
});
