<template lang="pug">
  .container.mt-5
    .row.text-center.mt-5
      .col-4.offset-2.bg-transparent.p-0.k-swv
        .container.text-white.text-left.h-100
          .row.h-100.mt-5.k-pex
            .col-10.offset-1.d-flex.align-items-center
              div.mt-5
                h3.congratz
                  strong Hola {{ firstName }},
                h3 Uno de nuestros Ejecutivos Comerciales se estar&aacute; contactando contigo para asesorarte.
      //- .col-4.p-0
        //- img.img-fluid(src='')
      .col.align-items-center.k-show-mob
        div.mt-5
          h3.congratz.text-white
            strong Hola {{ firstName }},
          h3.text-white Uno de nuestros Ejecutivos Comerciales se estar&aacute; contactando contigo para asesorarte.
    .row.mt-4.text-center
      .col-12
        form(
          novalidate=''
          @submit.prevent='onSubmit'
          data-vv-scope='form1')
          br
          br
          br
          br
          br
          button.btn.btn-success.btn-md.btn-xs-block(
            type='submit' href='javascript:void(0)' @click='close') Cerrar
</template>

<script>
import { mapGetters } from 'vuex';
import solicitudTypes from '@/store/types/solicitud';

export default {
  name: 'UnEjecutivoContactara',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        tipoProductoId: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      firstName: solicitudTypes.getters.getFirstName,
    }),
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getUnEjecutivoContactara();
        if (data) {
          this.CommonService.assign(this.form, data);
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async onSubmit() {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        // try {
        //   this.$wait.start('global');
        //   const data={};
        //   await this.SolicitudService.putUnEjecutivoContactara(data);
        //   this.$store.commit(solicitudTypes.mutations.logout);
        // } catch (err) {
        //   this.$swal({ type: err.type, text: err.message });
        // } finally {
        //   this.$wait.end('global');
        // }
      }
    },
    close() {
      window.close();
    },
  },
};
</script>

<style lang="scss" scoped="">
  .mute {
    font-size: 0.7rem;
  }
  .k-px{
      text-align: right;
  }
  h3 {
    text-align: right;
  }
  .k-show-mob {
    display: none;
    visibility: hidden;
  }
  @media only screen and (max-width:620px) {
    h3 {
      font-size: 1.2rem;
      text-align: center;
    }
    .k-px{
      text-align: center;
    }
    .k-show-mob {
      display: block;
      visibility: visible;
    }
    .k-swv {
      display: none;
      visibility: hidden;
    }
  }

</style>
