<template lang="pug">
  .container.containerxs
    .row
      .col-12.col-md-8.offset-md-2.mt-4
        //- h5.mt-3.mb-0.text-center.text-dark {{ $t('pin.thanks') }}
        //- h5.mb-3.text-center.text-dark {{ $t('pin.title') }}
        form(
          novalidate=''
          @submit.prevent='onSubmit'
          data-vv-scope='form1')
          .row
            .col.text-center
              span.mt-3.mb-2.mb-0(style="font-family: 'BebasNeuePro-Bold'; color: #4A4A4A; font-size: 16px") {{ $t('pin.notice1') }}
          .row
            .col.text-center.mt-4
              input.form-control.pin(
                :placeholder='$t("pin.form1.d1.placeholder")'
                type='text'
                ref='d1'
                maxlength='1'
                @keyup='onInputSelection("d2", $event)'
                v-model='form.d1')
              span.pin-separator
              input.form-control.pin(
                :placeholder='$t("pin.form1.d2.placeholder")'
                type='text'
                ref='d2'
                maxlength='1'
                @keyup='onInputSelection("d3", $event)'
                v-model='form.d2')
              span.pin-separator
              input.form-control.pin(
                :placeholder='$t("pin.form1.d3.placeholder")'
                type='text'
                ref='d3'
                maxlength='1'
                @keyup='onInputSelection("d4", $event)'
                v-model='form.d3')
              span.pin-separator
              input.form-control.pin(
                :placeholder='$t("pin.form1.d4.placeholder")'
                type='text'
                ref='d4'
                maxlength='1'
                @keyup='onInputSelection("d1", $event)'
                v-model='form.d4')
            .w-100
            .col.text-center.mt-4
              span.text-center.mt-4(style="font-family: 'BebasNeuePro-Bold'; font-size: 16px;color: #4A4A4A;") {{ $t('pin.notice2') }}
              br
              button.btn.btn-link.text-primary(
                  style="text-decoration: underline;font-family: 'BebasNeuePro-Bold'; font-size: 16px;"
                  @click='requestPin'
                  type='button') {{ $t('pin.notice3') }}
          .pt-3
            .row.botonxs
              .col-12.col-sm-12.offset-sm-12.order-2.order-xs-1.text-center
                button.btn.btn-success.btn-md.btn-xs-block.claseboton(
                  :disabled='!isReady'
                  type='submit') {{ $t('components.navigation.submit') }}
                  font-awesome-icon.ml-3(
                    role='button'
                    focusable='false'
                    tabindex='-1'
                    icon='arrow-right')
</template>

<script>
import solicitudTypes from '@/store/types/solicitud';

function setInputSelection(input, startPos, endPos) {
  if (input.setSelectionRange) {
    input.focus();
    input.setSelectionRange(startPos, endPos);
  } else if (input.createTextRange) {
    const range = input.createTextRange();
    range.collapse(true);
    range.moveEnd('character', endPos);
    range.moveStart('character', startPos);
    range.select();
  }
}

function setInputPos(input, pos) { /* eslint-disable-line */
  setInputSelection(input, pos, pos);
}

export default {
  name: 'Pin',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        d1: null,
        d2: null,
        d3: null,
        d4: null,
      },
    };
  },
  computed: {
    isReady() {
      return !!this.form.d1 && !!this.form.d2 && !!this.form.d3 && !!this.form.d4;
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getPin();
        if (data) {
          this.CommonService.assign(this.form, data);
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async onSubmit() {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        try {
          this.$wait.start('global');
          const result = await this.SolicitudService.putPin({ pin: `${this.form.d1}${this.form.d2}${this.form.d3}${this.form.d4}` });
          this.$store.commit(solicitudTypes.mutations.setView, result.view);
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      }
    },
    async requestPin() {
      try {
        this.$wait.start('global');
        const flag = await this.SolicitudService.requestPin();
        this.$swal({
          type: (flag ? 'success' : 'warning'),
          title: 'Listo',
          text: (flag ? 'Nuevo c\u00F3digo enviado a tu celular' : 'Nuevo c\u00F3digo enviado a tu correo electr\u00F3nico'),
        });
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    onInputSelection(ref) {
      setInputSelection(this.$refs[ref], 0, -1);
    },
  },
};
</script>

<style lang="scss">
  .pin {
    width: 75px;
    height: 75px;
    display: inline-block;
    font-size: 35px;
    text-align: center;
    font-size: 34px;
    font-family: 'BebasNeuePro-Bold';
    color: #4A4A4A;
  }
  .pin-separator {
    position: relative;
    top: -5px;
    margin-left: 2vh;
    margin-right: 2vh;
  }

  @media only screen and (max-width:620px) {
    .pin {
      width: 50px;
      height: 55px;
      display: inline-block;
      font-size: 35px;
      text-align: center;
    }
    .pin-separator {
      position: relative;
      top: -5px;
      margin-left: 1vh;
      margin-right: 1vh;
    }
  }
</style>
