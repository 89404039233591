import namespace from '@/store/namespace';

export default namespace('solicitud', {
  getters: [
    'getTipoProductoId',
    'getView',
    'getToken',
    'getFullname',
    'getFirstName',
  ],
  mutations: [
    'cargaInicialRequest',
    'cargaInicialSuccess',
    'cargaInicialFailure',
    'validacionInicialRequest',
    'validacionInicialSuccess',
    'validacionInicialFailure',
    'logout',
    'setView',
    'volverRequest',
    'volverSuccess',
    'volverFailure',
  ],
  actions: [
    'dniChecker',
    'cargaInicial',
    'validacionInicial',
    'logout',
    'volver',
  ],
});
