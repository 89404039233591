<template lang="pug">
  label.btn.btn-light.btn-sm.btn-block.fontSize(
    :class='{ active: checked == value }'
    :for='id')
    span.float-right(
      v-if='info'
      ref='info'
      data-toggle='tooltip'
      data-placement='top'
      :title='info')
      font-awesome-icon(
        ref='info'
        role='button'
        focusable='false'
        tabindex='-1'
        icon='info-circle')
    div.hidden
      p
    input.custom-control-input(
      :id='id'
      :name='name'
      type='radio'
      autocomplete='off'
      :value='value'
      :checked='checked == value'
      @change='$emit("change", $event.target.value)'
      :class='{"is-invalid": error}')
    | {{ label }}
</template>

<script>
import $ from 'jquery';

export default {
  name: 'KButtonRadio',
  inheritAttrs: false,
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.checked;
    },
    validator: 'new',
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {},
    name: String,
    label: String,
    error: String,
    value: {},
    info: String,
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
    if (this.info) {
      $(this.$refs.info).tooltip();
    }
  },
};
</script>
<style>
.hidden{
  width: 0px;
}
.fontSize{
  font-size: 14px !important;
}
</style>
