<template lang="pug">
  .container.containerxsDatos
    .row
      .col-12.col-md-10.offset-md-1
        form.mt-5(
          novalidate=''
          @submit.prevent='onSubmit'
          data-vv-scope='form1')
          .row
            .col-12.col-sm-12.col-md-6
              k-button-group(
                :label='$t("datosLaborales.form1.incomeSource.label")'
                :error='errors.first("form1.tipoIngresoId")')
                k-button-radio(
                  :label='"Dependiente"'
                  :data-vv-as='$t("datosLaborales.form1.incomeSource.label")'
                  name='fuenteIngresoId'
                  value='20077'
                  class="fuenteIngreso"
                  v-validate='"required"'
                  :error='errors.first("form1.fuenteIngresoId")'
                  v-model='form.fuenteIngresoId')
                k-button-radio(
                  :label='"Independiente"'
                  name='fuenteIngresoId'
                  value='20076'
                  class="fuenteIngreso"
                  :error='errors.first("form1.fuenteIngresoId")'
                  v-model='form.fuenteIngresoId')
            .col-12.col-sm-12.col-md-6
              k-button-group(
                :label='$t("datosLaborales.form1.tipoIngreso.ghost")'
                :error='errors.first("form1.tipoIngresoId")')
                k-button-radio(
                  :label='$t("ingresos.form1.tipoIngresoId.options[0].label")'
                  :data-vv-as='$t("datosLaborales.form1.tipoIngreso.label")'
                  name='tipoIngresoId'
                  value='F'
                  class="tipoIngreso"
                  v-validate='"required"'
                  :error='errors.first("form1.tipoIngresoId")'
                  v-model='form.tipoIngresoId')
                k-button-radio(
                  :label='$t("ingresos.form1.tipoIngresoId.options[1].label")'
                  name='tipoIngresoId'
                  value='V'
                  class="tipoIngreso"
                  :error='errors.first("form1.tipoIngresoId")'
                  v-model='form.tipoIngresoId')
            .col-12.col-sm-12.col-md-12.mt-2
              k-input(
                :disabled='form.fuenteIngresoId === null'
                :label="`${form.fuenteIngresoId === '20077' ? $t('datosLaborales.form1.rucEmpleadorSolicitante.label') : $t('datosLaborales.form1.rucEmpleadorSolicitante.label2')}`"
                :data-vv-as='$t("datosLaborales.form1.rucEmpleadorSolicitante.label")'
                name='rucEmpleadorSolicitante'
                maxlength='11'
                v-validate='"required|numeric|min:11|max:11|checkRuc"'
                :error='errors.first("form1.rucEmpleadorSolicitante")'
                v-model='form.rucEmpleadorSolicitante'
                :help-text='form.razonSocialEmpleadorSolicitante')
          .row.mt-4.pt-1
            .col-12.col-sm-12.col-md-7
              k-input(
                :label='$t("datosLaborales.form1.incomes.label")'
                :placeholder='$t("datosLaborales.form1.incomes.placeholder")'
                :data-vv-as='$t("datosLaborales.form1.incomes.label")'
                name='ingresos'
                maxlength='11'
                v-validate='"required|decimal|min:4|max:11|min_value:1500"'
                :error='errors.first("form1.ingresos")'
                v-model='form.ingresos')
            .col-12.col-sm-12.col-md-5.mt-4.mt-md-0
              k-date-picker.datePicker(
                :label='$t("datosLaborales.form1.fechaIngreso.label")'
                :placeholder='$t("datosLaborales.form1.fechaIngreso.placeholder")'
                :data-vv-as='$t("datosLaborales.form1.fechaIngreso.label")'
                name='fechaIngreso'
                maxlength='10'
                v-validate='"required|date_format:yyyy-MM-dd|checkDateIngreso"'
                :error='errors.first("form1.fechaIngreso")'
                v-model='form.fechaIngreso')
          template(v-if='showBeforeJob')
            .row.mt-3
              .col-12.col-sm-12.col-md-7
                k-input(
                  :label='$t("datosLaborales.form1.ingresoAnterior.label")'
                  :placeholder='$t("datosLaborales.form1.ingresoAnterior.placeholder")'
                  :data-vv-as='$t("datosLaborales.form1.ingresoAnterior.label")'
                  name='ingresoAnterior'
                  maxlength='11'
                  v-validate='"required|decimal|min:4|max:11|min_value:1500"'
                  :error='errors.first("form1.ingresoAnterior")'
                  v-model='form.ingresoAnterior')
              .col-12.col-sm-12.col-md-5.mt-4.mt-md-0
                k-date-picker.datePicker(
                  :label='$t("datosLaborales.form1.fechaIngresoAnterior.label")'
                  :placeholder='$t("datosLaborales.form1.fechaIngresoAnterior.placeholder")'
                  :data-vv-as='$t("datosLaborales.form1.fechaIngresoAnterior.label")'
                  name='fechaIngresoAnterior'
                  maxlength='10'
                v-validate='"required|date_format:yyyy-MM-dd|checkDateIngresoAnterior"'
                  :error='errors.first("form1.fechaIngresoAnterior")'
                  v-model='form.fechaIngresoAnterior')
          template(v-if='showIncomes')
            .row.mt-3
              .col-12.col-sm-12.col-md-7
                k-input(
                  :label='$t("datosLaborales.form1.incomes1.label")'
                  :placeholder='$t("datosLaborales.form1.incomes1.placeholder")'
                  :data-vv-as='$t("datosLaborales.form1.incomes1.label")'
                  name='ingresos1'
                  maxlength='11'
                  v-validate='"required|decimal|min:4|max:11|min_value:1500"'
                  :error='errors.first("form1.ingresos1")'
                  v-model='form.ingresos1')
              .col-12.col-sm-12.col-md-7.mt-4
                k-input(
                  :label='$t("datosLaborales.form1.incomes2.label")'
                  :placeholder='$t("datosLaborales.form1.incomes2.placeholder")'
                  :data-vv-as='$t("datosLaborales.form1.incomes2.label")'
                  name='ingresos2'
                  maxlength='11'
                  v-validate='"required|decimal|min:4|max:11|min_value:1500"'
                  :error='errors.first("form1.ingresos2")'
                  v-model='form.ingresos2'
                  v-if='moreSixMonths')
          .pt-3
            .row.botonxs
              .col-7.col-sm-7.order-2.text-left.px-1
                button.btn.btn-success.btn-md.btn-xs-block.font-weight-bold.claseboton
                  | {{ $t("components.navigation.submit") }}
                  font-awesome-icon.ml-1(
                    role='button'
                    focusable='false'
                    tabindex='-1'
                    icon='arrow-right')
              .col-5.col-sm-5.order-1.text-right
                button.btn.btn-plomo.btn-md.btn-xs-block.font-weight-bold.claseboton(
                  @click='goToBack'
                  type='button')
                  font-awesome-icon.mr-1(
                    role='button'
                    focusable='false'
                    tabindex='-1'
                    icon='arrow-left')
                  | {{ $t("components.navigation.return") }}
          br
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import solicitudTypes from '@/store/types/solicitud';
import moment from 'moment-timezone';
import { Validator } from 'vee-validate';

import fuenteIngreso from '@/data/fuenteIngreso.json';
import tipoIngreso from '@/data/tipoIngreso.json';

export default {
  name: 'DatosLaborales',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        fuenteIngresoId: null,
        rucEmpleadorSolicitante: null,
        razonSocialEmpleadorSolicitante: null,
        ingresos: null,
        fechaIngreso: null,
        ingresoAnterior: null,
        fechaIngresoAnterior: null,
        tipoIngresoId: null,
      },
      disabledDates: {
        from: new Date(),
      },
    };
  },
  computed: {
    fuenteIngreso() {
      return fuenteIngreso.map((d) => ({ value: d.id, text: d.name }));
    },
    tipoIngreso() {
      return tipoIngreso.map((d) => ({ value: d.id, text: d.name }));
    },
    showIncomes() {
      if (this.form.tipoIngresoId === 'V') {
        return true;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.ingresos1 = null;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.ingresos2 = null;
      return false;
    },
    moreSixMonths() {
      if (this.form.tipoIngresoId === 'V') {
        if (this.form.fechaIngreso) {
          const hasta = moment().subtract(1, 'months').endOf('month');
          let desde = moment().format(this.form.fechaIngreso);
          if (moment().format(this.form.fechaIngreso, 'DD') > 1) {
            desde = moment().add(1, 'months').startOf('month').format(this.form.fechaIngreso);
          }
          if (moment(hasta).diff(desde, 'months') > 6) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.form.ingresos1 = null;
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.form.ingresos2 = null;
            return true;
          }
        } else {
          return true;
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.ingresos1 = null;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.ingresos2 = null;
        return false;
      }
      return false;
    },
    showBeforeJob() {
      if (this.form.fechaIngreso) {
        const hasta = moment().subtract(1, 'months').endOf('month');
        let desde = moment().format(this.form.fechaIngreso);

        if (moment().format(this.form.fechaIngreso, 'DD') > 1) {
          desde = moment().add(1, 'months').startOf('month').format(this.form.fechaIngreso);
        }
        if (moment(hasta).diff(desde, 'months') < 6) {
          return true;
        }
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.ingresoAnterior = null;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.fechaIngresoAnterior = null;
      return false;
    },
  },
  created() {
    Validator.extend('checkRuc', {
      getMessage: (field, params, data) => data.message,
      validate: async (value) => {
        this.form.razonSocialEmpleadorSolicitante = null;
        try {
          this.$wait.start('global');
          const info = await this.SolicitudService.getRucInfo({ ruc: value });
          this.form.razonSocialEmpleadorSolicitante = info.razonSocial;
          return { valid: true };
        } catch (err) {
          return { valid: false, data: { message: err.message } };
        } finally {
          this.$wait.end('global');
        }
      },
    }, {
      immediate: true,
    });

    Validator.extend('validateInt', {
      getMessage: (field, params, data) => data.message,
      // eslint-disable-next-line no-unused-vars
      validate: async (value) => {
        // this.form.razonSocialEmpleadorSolicitante = null;
        try {
          this.$wait.start('global');
          const monto = this.form.ingresos;
          const enteros = monto.toString().split('.')[0];

          if (enteros.length < 4) {
            return { valid: false, data: { message: 'Los montos deben ser mayor a 1000' } };
          }
          return { valid: true };
        } catch (err) {
          return { valid: false, data: { message: err.message } };
        } finally {
          this.$wait.end('global');
        }
      },
    }, {
      immediate: true,
    });

    Validator.extend('checkDateIngreso', {
      getMessage: (field, params, data) => data.message,
      validate: async () => {
        try {
          this.$wait.start('global');
          const today = moment().format('YYYY-MM-DD');
          const fecIngreso = moment().format(this.form.fechaIngreso);
          if (fecIngreso > today) {
            return { valid: false, data: { message: ' La Fecha de inicio de actividades no debe ser mayor a la fecha actual' } };
          }
          return { valid: true };
        } catch (err) {
          return { valid: false, data: { message: err.message } };
        } finally {
          this.$wait.end('global');
        }
      },
    }, {
      immediate: true,
    });

    Validator.extend('checkDateIngresoAnterior', {
      getMessage: (field, params, data) => data.message,
      validate: async () => {
        try {
          this.$wait.start('global');
          const fecIngreso = moment().format(this.form.fechaIngreso);
          const fecIngresoAnterior = moment().format(this.form.fechaIngresoAnterior);
          if (fecIngresoAnterior >= fecIngreso) {
            return { valid: false, data: { message: ' La Fecha de inicio del anterior centro laboral no debe ser mayor o igual a la fecha de inicio de actividades' } };
          }
          return { valid: true };
        } catch (err) {
          return { valid: false, data: { message: err.message } };
        } finally {
          this.$wait.end('global');
        }
      },
    }, {
      immediate: true,
    });

    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    ...mapWaitingActions({
      goToBack: {
        action: solicitudTypes.actions.volver,
        loader: 'global',
      },
    }),
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getDatosLaborales();
        if (data) {
          this.CommonService.assign(this.form, data);
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async onSubmit() {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        try {
          this.$wait.start('global');
          const data = { ...this.form };
          // delete data.razonSocialEmpleadorSolicitante;
          if (!this.showBeforeJob) {
            data.ingresoAnterior = null;
            data.fechaIngresoAnterior = null;
          }

          if (!this.showIncomes) {
            data.ingresos1 = null;
            data.ingresos2 = null;
          } else if (!this.moreSixMonths) {
            data.ingresos2 = null;
          }

          const result = await this.SolicitudService.putDatosLaborales(data);
          this.$store.commit(solicitudTypes.mutations.setView, result.view);
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      }
    },
  },
};
</script>
<style>
.datePicker{
  margin-right: 0px;

}

.datePicker input{
  margin-top: 8px;
  position: relative;
  font-size: 16px;
  height: 54px !important;
}

.datePicker div label{
  font-family: 'BebasNeuePro-Regular';
  position: absolute;
  pointer-events: none;
  left: 2;
  top: 20;
  background-color: #fff;
  padding: 0 2px;
  color: #000;
  margin-left: 10px;
  margin-top: -13px;
  font-size: 16px;
  min-width: 145px;
  z-index: 50;
}
.datePicker div.invalid-tooltip{
  position: absolute;
  z-index: 300 !important;
}
.tipoIngreso{
  margin-left: 0px;
  max-width: 100px;
  min-width: 100px;
}

.fuenteIngreso{
  margin-left: 0px;
  max-width: 120px;
  min-width: 120px;
}

@media (max-width:620px) {
  .containerxsDatos{
    margin-top: 230px;
    margin-bottom: 80px;
  }
  .fuenteIngreso{
    margin-left: 0px;
    max-width: 40.5vw;
    min-width: 40.5vw;
  }
  .tipoIngreso{
    max-width: 40.5vw;
    min-width: 40.5vw;
  }
}
</style>
