<template lang="pug">
  .container.containerxsCotizacion
    .row
      .col-12.col-md-8.offset-md-2
        //- h5.my-3.text-center.text-dark.bebasNeuePro {{ $t('informacionCotizacion.title') }}
        form(
          novalidate=''
          @submit.prevent='onSubmit'
          data-vv-scope='form1')
          dl.row.mb-0.mt-2
            dt.col.titlesCotizacion
              span {{ $t('informacionCotizacion.label.modelo') }}
            dd.row.text-right
              span.inputFinancing {{ form.modeloDescripcion }}
          dl.row.mb-0.mt-2
            dt.col.titlesCotizacion
              span {{ $t('informacionCotizacion.label.modelPrice') }}
            dd.row.text-right
              span.input-group-text.size16 {{ form.montoSolicitado | currency(form.monedaId).split(' ')[0] }}
              span.inputFinancing {{ form.montoSolicitado | currency(form.monedaId).split(' ')[1] }}
          dl.row.mb-0.mt-2
            dt.col.titlesCotizacion
              span {{ $t('informacionCotizacion.label.initialFee') }}
            dd.row.text-right
              span.input-group-text.size16 {{ (form.montoSolicitado * form.porcentaje)/100 | currency(form.monedaId).split(' ')[0] }}
              span.inputFinancing {{ (form.montoSolicitado * form.porcentaje)/100 | currency(form.monedaId).split(' ')[1]  }}
          dl.row.mb-0.mt-2(v-if="form.toyotaValueSoles && form.toyotaValueSoles > 0")
            dt.col.titlesCotizacion
              span {{ $t('informacionCotizacion.label.toyotaValue') }}
            dd.row.text-right
              span.inputFinancing {{ form.toyotaValue | currency(form.monedaId) }}
          dl.row.mb-0.mt-2
            dt.col.titlesCotizacion
              span {{ $t('informacionCotizacion.label.deadline') }}
            dd.row.text-right
              span.inputFinancing {{ form.numeroCuotas }} meses
          dl.row.mb-0.mt-2
            dt.col.titlesCotizacion
              span {{ $t('informacionCotizacion.label.finalFee') }}
            dd.row.text-right
              span.input-group-text.size16 {{ (form.montoSolicitado * form.porcentajeCuotaBalon)/100 | currency(form.monedaId).split(' ')[0] }}
              span.inputFinancing  {{ (form.montoSolicitado * form.porcentajeCuotaBalon)/100 | currency(form.monedaId).split(' ')[1] }}
          .row.mt-2
            .col-12.col-sm-12.col-md-4
              k-select(
                :label='$t("informacionCotizacion.form1.locationId.label")'
                :data-vv-as='$t("informacionCotizacion.form1.locationId.label")'
                name='ubicacionId'
                v-validate='"required"'
                :error='errors.first("form1.ubicacionId")'
                v-model='form.ubicacionId'
                :options='departamentos'
                :disabled='checkUbicacion'
                @change='form.ubicacionId = $event')
            .col-12.col-sm-12.col-md-4
              k-select(
                :label='$t("informacionCotizacion.form1.concessionaireId.label")'
                :data-vv-as='$t("informacionCotizacion.form1.concessionaireId.label")'
                name='concesionarioId'
                v-validate='"required"'
                :error='errors.first("form1.concesionarioId")'
                v-model='form.concesionarioId'
                :options='concesionarios'
                :disabled='checkConcesionario'
                @change='form.concesionarioId = $event')
            .col-12.col-sm-12.col-md-4
              k-select(
                :label='$t("informacionCotizacion.form1.currencyOfFinancing.label")'
                :data-vv-as='$t("informacionCotizacion.form1.currencyOfFinancing.label")'
                name='monedaId'
                v-validate='"required"'
                :error='errors.first("form1.monedaId")'
                v-model='form.monedaId'
                :options='monedas'
                @change='form.monedaId = $event; changeMoneda()')
          .row
            .col-12.col-sm-12.col-md-8
              k-button-group(
                :label='$t("informacionCotizacion.form1.goingToUseInDepartment.label")'
                :error='errors.first("form1.goingToUseInDepartment")'
                class='depaResides2')
                k-button-radio(
                  :label='$t("informacionCotizacion.form1.goingToUseInDepartment.options[0].label")'
                  :data-vv-as='$t("informacionCotizacion.form1.goingToUseInDepartment.label")'
                  name='departamentoDondeReside'
                  value='S'
                  class='depaResides'
                  v-validate='"required"'
                  :error='errors.first("form1.departamentoDondeReside")'
                  v-model='form.departamentoDondeReside')
                k-button-radio(
                  :label='$t("informacionCotizacion.form1.goingToUseInDepartment.options[1].label")'
                  name='departamentoDondeReside'
                  value='N'
                  class='depaResides'
                  :error='errors.first("form1.departamentoDondeReside")'
                  v-model='form.departamentoDondeReside')
            template( v-if='form.departamentoDondeReside === "N"' )
              .col-12.col-sm-12.col-md-4.mt-3.pt-1
                k-select(
                  :label='$t("informacionCotizacion.form1.departamentoId.label")'
                  :data-vv-as='$t("informacionCotizacion.form1.departamentoId.label")'
                  name='departamentoDeUsoId'
                  v-validate='"required"'
                  :error='errors.first("form1.departamentoDeUsoId")'
                  v-model='form.departamentoDeUsoId'
                  :options='departamentos'
                  @change='form.departamentoDeUsoId = $event')
          .row.pl-3.py-2.mb-3.mb-md-0(style="font-family: 'BebasNeuePro'; color: #000") {{ $t('informacionCotizacion.label.referenceText') }}
          .pt-3
            .row.botonxs
              .col-7.col-sm-7.order-2.text-left.px-1
                button.btn.btn-success.btn-md.btn-xs-block.font-weight-bold.claseboton
                  | {{ $t("components.navigation.submit") }}
                  font-awesome-icon.ml-1(
                    role='button'
                    focusable='false'
                    tabindex='-1'
                    icon='arrow-right')
              .col-5.col-sm-5.order-1.text-right
                button.btn.btn-plomo.btn-md.btn-xs-block.font-weight-bold.claseboton(
                  @click='goToBack'
                  type='button')
                  font-awesome-icon.mr-1(
                    role='button'
                    focusable='false'
                    tabindex='-1'
                    icon='arrow-left')
                  | {{ $t("components.navigation.return") }}
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import solicitudTypes from '@/store/types/solicitud';

import departamentos from '@/data/departamentos.json';
import ubicaciones from '@/data/ubicaciones.json';
import concesionarios from '@/data/concesionarios.json';
import monedas from '@/data/monedas.json';

export default {
  name: 'InformacionCotizacion',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        modeloDescripcion: null,
        montoSolicitado: null,
        porcentaje: null,
        numeroCuotas: null,
        montoVehiculoSoles: null,
        montoVehiculoDolares: null,
        porcentajeCuotaBalon: null,
        ubicacionId: null,
        concesionarioId: null,
        monedaId: null,
        departamentoDondeReside: null,
        departamentoDeUsoId: null,
        toyotaValueSoles: null,
        toyotaValueDolares: null,
        toyotaValue: null,
      },
    };
  },
  computed: {
    departamentos() {
      return departamentos.map((d) => ({ value: d.id, text: d.name }));
    },
    ubicaciones() {
      return ubicaciones.map((d) => ({ value: d.id, text: d.name }));
    },
    concesionarios() {
      return concesionarios.map((d) => ({ value: d.id, text: d.name }));
    },
    monedas() {
      return monedas.map((d) => ({ value: d.id, text: d.name }));
    },
    checkUbicacion() {
      return this.form.ubicacionId && this.form.ubicacionId.length > 0;
    },
    checkConcesionario() {
      return this.form.concesionarioId && this.form.concesionarioId.length > 0;
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    ...mapWaitingActions({
      goToBack: {
        action: solicitudTypes.actions.volver,
        loader: 'global',
      },
    }),
    changeMoneda() {
      if (this.form.monedaId === 'USD') {
        this.form.montoSolicitado = this.form.montoVehiculoDolares;
        this.form.toyotaValue = this.form.toyotaValueDolares;
      } else if (this.form.monedaId === 'PEN') {
        this.form.montoSolicitado = this.form.montoVehiculoSoles;
        this.form.toyotaValue = this.form.toyotaValueSoles;
      } else {
        // empty
      }
    },
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getInformacionCotizacion();
        if (data) {
          this.CommonService.assign(this.form, data);
          this.changeMoneda();
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async onSubmit() {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        try {
          this.$wait.start('global');
          const data = {
            ubicacionId: this.form.ubicacionId,
            concesionarioId: this.form.concesionarioId,
            monedaId: this.form.monedaId,
            montoSolicitado: this.form.montoSolicitado,
            departamentoDondeReside: this.form.departamentoDondeReside,
            departamentoDeUsoId: this.form.departamentoDeUsoId,
          };
          const result = await this.SolicitudService.putInformacionCotizacion(data);
          this.$store.commit(solicitudTypes.mutations.setView, result.view);
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      }
    },
  },
};
</script>
<style>
.depaResides{
  margin-left: 0px;
  max-width: 80px !important;
  min-width: 80px !important;
}
.titlesCotizacion{
    font-size: 16px !important;
    font-family: 'BebasNeuePro-Bold';
    color:#616161;
    max-width: 90%;
    padding-top: 15px !important;
  }
.input-group-text{
  background-color: #4A4A4A;
  color: #FFF;
  margin-right: 4px;
  border-radius: 4px !important;
  padding-left: 25px;
  padding-right: 25px;
  max-height: 50px;
}
.inputFinancing{
  height: 50px;
  background-color: #E9EAE8 !important;
  font-family: 'BebasNeuePro-Regular';
  border-radius: 4px !important;
  align-items: center;
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  font-size: 16px;
  width: 300px;
  margin-right: 32px;
  border: 1px solid #c6c7c7;
  text-align: center;
}
.size16{
  font-size: 16px;
}
@media ( max-width: 600px) {
  .inputFinancing{
    width: 150px;
  }
}
.claseboton{
  border-radius: 3px 25px 3px 3px;
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
}
.bebasNeuePro{
    font-size: 28px;
    font-family: 'BebasNeuePro-Bold';
    color: #4A4A4A !important;
    padding-left: 40px;
    padding-right: 40px;
  }
@media (max-width:620px) {
  .containerxsCotizacion{
    margin-top: 280px;
    margin-bottom: 80px;
  }
  .depaResides{
    margin-left: 0px;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .depaResides2{
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
</style>
