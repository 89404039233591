<template lang="pug">
  div
    vue-element-loading(
      :active='$wait.waiting("global")'
      :is-full-screen='true')
      vue-simple-spinner(
        :size='50'
        :line-size='7'
        line-fg-color="#EB0A1E"
        line-bg-color="#E9EAE8"
        :message='$t("messages.processing")')
    vue-element-loading(
      :active='$wait.waiting("uploading")'
      :is-full-screen='true')
      vue-simple-spinner(
        :size='50'
        :line-size='7'
        line-fg-color="#EB0A1E"
        line-bg-color="#E9EAE8"
        :message='$t("messages.uploading")')
    router-view
</template>

<script>
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'getAlertField',
  mutationType: 'updateAlertField',
});

export default {
  name: 'App',
  computed: mapFields([
    'type',
    'message',
    'show',
  ]),
  watch: {
    show(newValue) {
      if (newValue) {
        this.$swal({
          type: this.type,
          text: this.message,
        }).then(() => {
          this.show = false;
        });
      }
    },
  },
};
</script>

<style lang="scss">
  @import '@/assets/custom.scss';
  @import '@/../node_modules/bootstrap/scss/bootstrap.scss';
  @font-face {
  font-family: "BebasNeuePro";
  src: local("BebasNeuePro"),
    url('./assets/font/BebasNeuePro-ExpandedBook.ttf') format("truetype");
  }
  @font-face {
  font-family: "BebasNeuePro-Bold";
  src: local("BebasNeuePro-Bold"),
    url('./assets/font/BebasNeuePro-SemiExpBold.ttf') format("truetype");
  }
  @font-face {
  font-family: "BebasNeuePro-expBold";
  src: local("BebasNeuePro-Bold"),
    url('./assets/font/BebasNeuePro-ExpandedBold.ttf') format("truetype");
  }
  @font-face {
  font-family: "BebasNeuePro-Regular";
  src: local("BebasNeuePro-Regular"),
    url('./assets/font/BebasNeuePro-ExpandedRegular.ttf') format("truetype");
  }
  html, body {
    background-color: #fff;
  }
  label.btn-light {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-left: 6px;
    margin-right: 6px;
    border-radius:  6px !important;
    border-color: #4A4A4A;
  }
  // label.btn-light:not(:disabled):not(.disabled).active {
  //   color: #fff;
  //   background-color: #08255a;
  //   border-color: #08255a;
  // }
  label.btn-light:not(:disabled):not(.disabled).active {
    color: #4A4A4A;
    background-color: #fff;
    height: 50px;
    border-width: 2.5px;
    margin-right: 6px;
    border-radius:  36px 6px 6px 6px !important;
    border-color: #4A4A4A;
    display: flex;
    justify-content: space-around;
    div{
      margin-right: 5px;
      height: 18px;
      width: 18px;
      border-radius: 999px;
      background: #4A4A4A;
      display: flex;
      align-items: center;
      justify-content: center;
      p{
        // height: 6px;
        // width: 6px;
        // border-width: 2px;
        // background: #4A4A !important;
        margin-top: 13px;
        display: block;
        width: 6px;
        height: 10px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }

  @include media-breakpoint-only(xs) {
    .btn-xs-block {
      display: block;
      width: 100%;
      margin-bottom: 0.5rem !important;
    }
    .order-xs-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }
    .order-xs-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }
  }

  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #c1c1c1 !important;
  }
  input::-moz-placeholder { /* Firefox 19+ */
    color: #c1c1c1 !important;
  }
  input:-ms-input-placeholder { /* IE 10+ */
    color: #c1c1c1 !important;
  }
  input:-moz-placeholder { /* Firefox 18- */
    color: #c1c1c1 !important;
  }
</style>
