<template lang="pug">
  .form-group.position-relative
    .clearfix.position-relative
      label.float-left(:for='id' v-text='label')
      span.float-right.mt-1(
        v-if='info'
        ref='info'
        data-toggle='tooltip'
        data-placement='top'
        :title='info')
        font-awesome-icon(
          role='button'
          focusable='false'
          tabindex='-1'
          icon='info-circle'
          size='lg')
    textarea.form-control.form-control-md(
      :id='id'
      :name='name'
      :type='type'
      :maxlength='maxlength'
      :rows='rows'
      :placeholder='placeholder'
      autocomplete='off'
      :value='value'
      @input='$emit("input", $event.target.value)'
      :class='{"is-invalid": error}')
    .invalid-tooltip(v-if='error' v-text='error')
</template>

<script>
import $ from 'jquery';

export default {
  name: 'KTextarea',
  inheritAttrs: false,
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
    validator: 'new',
  },
  props: {
    value: {},
    name: String,
    label: String,
    error: String,
    type: {
      type: String,
      default: 'text',
    },
    maxlength: {
      type: String,
      default: '255',
    },
    placeholder: {
      type: String,
      default: '',
    },
    info: String,
    rows: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
    if (this.info) {
      $(this.$refs.info).tooltip();
    }
  },
};
</script>
