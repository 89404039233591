<template lang="pug">
  .atendido
</template>

<script>
import Vue from 'vue';

export default {
  name: 'Atendido',
  created() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const ref = urlParams.has('ref') && urlParams.get('ref');
      if (ref) {
        const solicitud = atob(ref) && JSON.parse(atob(ref));
        let text = `Hola ${solicitud.cliente}, tu solicitud de crédito `;
        if (solicitud.stateId === 9) text += 'ha sido derivada a un analista.';
        else text += 'ha expirado, ingresa una nueva solicitud!';
        this.$swal({
          icon: 'warning',
          text,
        }).then(() => {
          window.location.href = '//mafperu.com';
        });
      }
    } catch (error) {
      Vue.$log.debug(error);
    }
  },
};
</script>

<style lang="sass">
  .atendido
    width: 100%
    height: 100vh
    background: #08255A
</style>
