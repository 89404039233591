<template lang="pug">
  .form-group.position-relative
    label.labelGroup.prettyLabel(v-text='label')
    br
    .form-control-md.btn-group.btn-group-toggle.margin(
      :class='{"is-invalid": error}')
      slot
    .invalid-tooltip(v-if='error' v-text='error')
</template>

<script>
export default {
  name: 'KButtonGroup',
  inheritAttrs: false,
  props: {
    label: String,
    error: String,
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
  },
};
</script>
<style>
.prettyLabel{
  font-family: 'BebasNeuePro';
  color: black;
  font-size: 16px;
}
.labelGroup{
  font-family: 'BebasNeuePro-Bold';
  color: black;
}
.margin{
  margin-left: 20px;
}
@media (max-width:620px) {
  .margin{
    margin-left: 0px;
  }
}
</style>
