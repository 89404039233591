<template lang="pug">
  .container-fluid(:class='root')
    .row.align-items-center(:class='subroot')
      .mx-auto(:class='incrementalStyle')
        slot
</template>

<script>
export default {
  name: 'KCenter',
  inheritAttrs: true,
  props: {
    full: Boolean,
    backdrop: Boolean,
    incremental: Boolean,
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
  },
  computed: {
    root() {
      return {
        'vh-content': !this.full,
        'vh-100': this.full,
        'fixed-top': this.full,
        'bbva-backdrop': this.backdrop,
      };
    },
    subroot() {
      return {
        'vh-content': !this.full,
        'vh-100': this.full,
      };
    },
    incrementalStyle() {
      return this.incremental ? ['col-sm-12', 'col-md-10', 'col-lg-8', 'col-xl-6'] : ['col-auto'];
    },
  },
};
</script>
