<template lang="pug">
  .h-100.d-flex.align-items-end.containerxsDictamen
    .w-100.bg-primary
      .row.imagenFondoEnd2.w-100.p-0.m-0
        .col.imagenFondoFin.p-4.m-0
        .col.m-0.p-0.p-sm-4.m-md-3.modalxsFin(style="display: flex; justify-content: center;")
          .col.bgwhite(style="max-width: 500px; border-radius: 4px")
            .row(style="display: flex; justify-content: center;")
                img.img_size.py-3.img_size2(src='@/assets/images/icon_felicidades_Daniel_End.svg')
            .row(style="display: flex; justify-content: center;")
              h2.pb-2.card-title3 Felicidades {{ firstName }},
              h2.card-title4.text-center Haz completado de forma
                br
              h2.card-title4.text-center(style="display: flex; justify-content: center;") satisfactoria el env&iacute;o de documentos
                br
            .row.mt-3(style="display: flex; justify-content: center;")
              <h5 class="text-plomo infoDictum">Validaremos la información y pronto nos comunicaremos contigo.</h5>
            .row(style="display: flex; justify-content: center;")
              form(
                novalidate=''
                @submit.prevent='onSubmit(null)'
                data-vv-scope='form1')
                .pt-3.pb-5
                  .row
                    .col-12.col-sm-12.offset-sm-12
                      button.btn.btn-plomo.btn-md.btn-xs-block.claseboton.px-5(
                        type='submit' href='javascript:void(0)' @click='backInicio')
                        font-awesome-icon.ml-1(
                          role='button'
                          focusable='false'
                          tabindex='-1'
                          icon='arrow-left')
                        |  &nbsp;&nbsp; Volver a MAF
</template>

<script>
import { mapGetters } from 'vuex';
import solicitudTypes from '@/store/types/solicitud';

export default {
  name: 'Fin',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        tipoProductoId: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      firstName: solicitudTypes.getters.getFirstName,
      token: solicitudTypes.getters.getToken,
    }),
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getFin();
        if (data) {
          this.CommonService.assign(this.form, data);
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async onSubmit() {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        try {
          this.$wait.start('global');
          await this.SolicitudService.putFin({});
          this.$store.commit(solicitudTypes.mutations.logout);
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      }
    },
    download() {
      window.open(`${process.env.VUE_APP_API_URL}/printPdf?auth=${this.token}`, '_blank');
    },
    backInicio() {
      window.open('https://mafperu.com/', '_self');
    },
  },
};
</script>

<style lang="scss">
img.download {
}
h2 {
  font-size: 1.7rem
}
.bgwhite{
  background-color: #fff;
}
@media only screen and (max-width:620px) {
  h2 {
    font-size: 1.4rem;
  }
  .img_size {
    width: 50%;
  }
}
.img_size2{
  /* max-height: 100%; */
  width: 100%;
  max-width: 85px;
}
.imagenFondoEnd2{
  background-image: url('../assets/images/img_feliciddades_daniel.png');
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 50vw;
  height: 100%;
}
.infoDictum{
  font-size: 16px;
  font-family: 'BebasNeuePro-Bold';
  color: #616161 !important;
}
@media (max-width:620px) {
  .imagenFondoFin{
    background-image: url('../assets/images/img_feliciddades_daniel.png');
    background-size: contain;
    background-position: left top 50px !important;
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: 100vw;
    height: 500px;
  }
  .bgwhite{
    background-color: transparent;
    margin: 4px;
  }
  .modalxsFin{
    position: fixed;
    top: 40vh !important;
    left: 0;
    padding: 0;
    width: 100% !important;
    border-radius:50px 0px 0px 0px;
    background-color: #fff;
    z-index: 100;
  }
}
</style>
