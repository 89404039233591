<template lang="pug">
  .custom-control.custom-radio(:class='{"is-invalid": error}')
    input.custom-control-input(
      :id='id'
      :name='name'
      type='radio'
      autocomplete='off'
      :value='value'
      :checked='checked == value'
      @change='$emit("change", $event.target.value)'
      :class='{"is-invalid": error}')
    label.custom-control-label(:for='id' v-text='label')
</template>

<script>
export default {
  name: 'KRadio',
  inheritAttrs: false,
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.checked;
    },
    validator: 'new',
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {},
    name: String,
    label: String,
    error: String,
    value: {},
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
  },
};
</script>
