<template lang="pug">
  div
    k-modal(
      v-if='show'
      @close='close')
      .row
        .col
          h5.my-3.text-center.text-dark.font-italic Preg&uacute;ntanos algo
          form(
            novalidate=''
            @submit.prevent='onSubmit1'
            data-vv-scope='form1')
            .form-row
              .col-12.col-sm-12.col-md-6
                k-select(
                  label='Tipo de consulta'
                  data-vv-as='Tipo de consulta'
                  name='tipoConsulta'
                  v-validate='"required"'
                  :error='errors.first("form1.tipoConsulta")'
                  v-model='form1.tipoConsulta'
                  :options='tiposConsultas'
                  @change='form1.tipoConsulta = $event')
              .col-12.col-sm-12.col-md-6
                k-input(
                  label='Nombre completo'
                  data-vv-as='Nombre completo'
                  name='nombreCompleto'
                  maxlength='250'
                  v-validate='"required|min:10|max:250"'
                  :error='errors.first("form1.nombreCompleto")'
                  v-model='form1.nombreCompleto')
            .form-row
              .col-12.col-sm-12.col-md-6
                k-input(
                  label='Tel\u00E9fono'
                  data-vv-as='Tel\u00E9fono'
                  name='telefono'
                  maxlength='9'
                  v-validate='"required|numeric"'
                  placeholder='Tel\u00E9fono'
                  :error='errors.first("form1.telefono")'
                  v-model.trim='form1.telefono')
              .col-12.col-sm-12.col-md-6
                k-input(
                  label='Correo electr\u00F3nico'
                  data-vv-as='Correo electr\u00F3nico'
                  name='correoElectronico'
                  maxlength='120'
                  v-validate='"required|email|min:10|max:70"'
                  placeholder='Correo electr\u00F3nico'
                  :error='errors.first("form1.correoElectronico")'
                  v-model.trim='form1.correoElectronico')
            .form-row
              .col-12.col-sm-12.col-md-12
                k-input(
                  label='Asunto'
                  data-vv-as='Asunto'
                  name='asunto'
                  maxlength='255'
                  v-validate='"required"'
                  placeholder='Asunto'
                  :error='errors.first("form1.asunto")'
                  v-model.trim='form1.asunto')
            .form-row
              .col-12.col-sm-12.col-md-12
                k-textarea(
                  label='Mensaje'
                  data-vv-as='Mensaje'
                  name='mensaje'
                  placeholder= 'Mensaje'
                  v-validate='"required"'
                  :error='errors.first("form1.mensaje")'
                  v-model.trim='form1.mensaje'
                )
            .form-row
            .row
              .col-12.mb-3.text-center
                button.btn.btn-info.btn-md.btn-xs-block.font-weight-bold
                  | {{ $t("contactenos.actions.submit") }}
</template>

<script>
export default {
  name: 'Contactenos',
  dependencies: ['SolicitudService'],
  data: () => ({
    form1: {
      tipoConsulta: null,
      nombreCompleto: null,
      telefono: null,
      correoElectronico: null,
      asunto: null,
      mensaje: null,
    },
  }),
  computed: {
    tiposConsultas() {
      return [
        { text: 'Quiero m\u00E1s informaci\u00F3n sobre el cr\u00E9dito', value: 0 },
        { text: 'Tengo inconvenientes al generar mi solicitud', value: 1 },
      ];
    },
  },
  props: {
    show: Boolean,
  },
  methods: {
    async onSubmit1() {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        try {
          this.$wait.start('global');
          await this.SolicitudService.contactenos({ ...this.form1 });
          this.form1.tipoConsulta = null;
          this.form1.nombreCompleto = null;
          this.form1.telefono = null;
          this.form1.correoElectronico = null;
          this.form1.asunto = null;
          this.form1.mensaje = null;
          this.close();
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      }
    },
    close() {
      this.$emit('update:show', false);
    },
  },
};
</script>
