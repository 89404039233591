import Vue from 'vue';
import { createHelpers } from 'vuex-map-fields';
import type from '@/store/types/alert';

const { getAlertField, updateAlertField } = createHelpers({
  getterType: 'getAlertField',
  mutationType: 'updateAlertField',
});

const state = {
  type: null,
  message: null,
  show: false,
};

const getters = {
  getAlertField,
};

const mutations = {
  [type.mutations.success](state, message) {
    state.type = 'success';
    state.message = message;
    state.show = true;
  },
  [type.mutations.warning](state, message) {
    state.type = 'warning';
    state.message = message;
    state.show = true;
  },
  [type.mutations.error](state, error) {
    Vue.$log.error(error);
    state.type = error.type;
    state.message = error.message;
    state.show = true;
  },
  [type.mutations.clear](state) {
    state.type = null;
    state.message = null;
    state.show = false;
  },
  updateAlertField,
};

const actions = {
  [type.actions.success]({ commit }, message) {
    commit(type.mutations.success, message);
  },
  [type.actions.warning]({ commit }, message) {
    commit(type.mutations.warning, message);
  },
  [type.actions.error]({ commit }, error) {
    commit(type.mutations.error, error);
  },
  [type.actions.clear]({ commit }) {
    commit(type.mutations.clear);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
