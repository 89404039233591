<template lang="pug">
.container.containerxs
    .row
      .col-12.col-md-10.offset-md-1
        //- h4.text-center.text-secondary.bg-primary.font-weight-bold.py-4 {{ $t('validacionInicial.title') }}
        form(
          novalidate=''
          @submit.prevent='onSubmit'
          data-vv-scope='form1')
          .form-row.mt-3
            .col-12.col-sm-12.col-md-4(@keydown.enter.stop)
              k-input(
                :label='$t("validacionInicial.form1.dni.label")'
                :data-vv-as='$t("validacionInicial.form1.dni.label")'
                name='dni'
                maxlength='8'
                placeholder='DNI'
                v-validate='"required|numeric|min:8|max:8"'
                :error='errors.first("form1.dni")'
                @blur="onDniChecker"
                v-model='form.dni'
                :disabled='formDisabled.dni')
            .col-12.col-sm-12.col-md-4.mt-3.pt-1.pt-md-0.mt-md-0
              k-select(
                :label='$t("validacionInicial.form1.estadoCivilId.label")'
                :data-vv-as='$t("validacionInicial.form1.estadoCivilId.label")'
                name='estadoCivilId'
                v-validate='"required"'
                :error='errors.first("form1.estadoCivilId")'
                v-model='form.estadoCivilId'
                :options='estadosCiviles'
                :disabled='formDisabled.others'
                @change='form.estadoCivilId = $event')
            .col-12.col-sm-12.col-md-4.mt-2.mt-md-0(:class='{ "col-md-4": hasConyuge || hasConviviente }')
              k-input(
                :label='$t("datosPersonales.form1.correoElectronico.label")'
                :data-vv-as='$t("datosPersonales.form1.correoElectronico.label")'
                name='correoElectronico'
                maxlength='70'
                placeholder='Correo Electrónico'
                v-validate='"required|email|min:10|max:70"'
                :error='errors.first("form1.correoElectronico")'
                :disabled='formDisabled.others'
                v-model='form.correoElectronico')
            .col-12.col-sm-12.col-md-12.mt-md-0(:class='{ "mt-3 pt-md-0 pt-1": form.direccion }')
              k-input(
                :label='$t("validacionInicial.form1.direccion.label")'
                :data-vv-as='$t("validacionInicial.form1.direccion.label")'
                name='direccion'
                maxlength='255'
                placeholder='Dirección'
                v-validate='"required|min:20|max:255|regex:^[^\'\]*$"'
                :error='errors.first("form1.direccion")'
                :disabled='formDisabled.others'
                v-model.trim='form.direccion')
            .col-12.col-sm-12.col-md-4.mx-0.mt-md-2(:class='{ "mt-3 pt-1 pt-md-0": form.departamentoId }')
              k-select(
                :label='$t("validacionInicial.form1.departamentoId.label")'
                :data-vv-as='$t("validacionInicial.form1.departamentoId.label")'
                name='departamentoId'
                v-validate='"required"'
                :error='errors.first("form1.departamentoId")'
                v-model='form.departamentoId'
                :options='departamentos'
                @change='form.departamentoId = $event; changeDepartamento()'
                :disabled='!formDisabled.others ? form.departamentoId !== null : formDisabled.others')
            .col-12.col-sm-12.col-md-4.mx-0.mt-2.mt-md-2
              k-select(
                :label='$t("validacionInicial.form1.provinciaId.label")'
                :data-vv-as='$t("validacionInicial.form1.provinciaId.label")'
                name='provinciaId'
                v-validate='"required"'
                :error='errors.first("form1.provinciaId")'
                v-model='form.provinciaId'
                :options='provincias'
                :disabled='formDisabled.others'
                @change='form.provinciaId = $event; changeProvincia()')
            .col-12.col-sm-12.col-md-4.mt-2.mt-md-2
              k-select(
                :label='$t("validacionInicial.form1.distritoId.label")'
                :data-vv-as='$t("validacionInicial.form1.distritoId.label")'
                name='distritoId'
                v-validate='"required"'
                :error='errors.first("form1.distritoId")'
                v-model='form.distritoId'
                :options='distritos'
                :disabled='formDisabled.others'
                @change='form.distritoId = $event')
            .col-12.col-sm-12.col-md-6.mt-3(:class='{ "mt-3": form.numeroTelefonoMovil }')
              k-input(
                :info='$t("datosPersonales.form1.numeroTelefonoMovil.info")'
                :label='$t("datosPersonales.form1.numeroTelefonoMovil.label")'
                :data-vv-as='$t("datosPersonales.form1.numeroTelefonoMovil.label")'
                name='numeroTelefonoMovil'
                maxlength='9'
                placeholder="Número de celular"
                v-validate='"required|numeric|min:9|max:9"'
                :error='errors.first("form1.numeroTelefonoMovil")'
                :disabled='formDisabled.others'
                v-model='form.numeroTelefonoMovil')
            .col-12.col-sm-12.col-md-6.mt-0.mt-md-2(:class='{ "mt-3  pt-1 pt-md-0": form.operadorTelefoniaMovilId }')
              k-select(
                :label='$t("datosPersonales.form1.operadorTelefoniaMovilId.label")'
                :data-vv-as='$t("datosPersonales.form1.operadorTelefoniaMovilId.label")'
                name='operadorTelefoniaMovilId'
                v-validate='"required"'
                :error='errors.first("form1.operadorTelefoniaMovilId")'
                v-model.number='form.operadorTelefoniaMovilId'
                :options='operadoresTelefoniaMovil'
                :disabled='formDisabled.others'
                @change='form.operadorTelefoniaMovilId = $event')
            .col-12.col-sm-12.col-md-6.pt-1(v-if='hasConyuge || hasConviviente')
              k-input(
                :label='labelConyugeOrConviviente'
                :data-vv-as='labelConyugeOrConviviente'
                name='dniConyuge'
                maxlength='8'
                v-validate='"required|numeric|min:8|max:8"'
                :error='errors.first("form1.dniConyuge")'
                :disabled='formDisabled.others'
                v-model='form.dniConyuge')
          k-checkbox.mt-1(
            :data-vv-as='$t("validacionInicial.form1.usoDeInformacionPersonal.validateAs")'
            name='usoDeInformacionPersonal'
            :error='errors.first("form1.usoDeInformacionPersonal")'
            v-model='form.usoDeInformacionPersonal')
            <span style="font-family: BebasNeuePro-Bold; font-size:16px">He leido y acepto <a href="https://s3.amazonaws.com/uat-documentos-maf/principales/Tratamiento+de+datos+personales.pdf" target="_blank"><u>las condiciones de tratamiento de mis datos personales</u></a></span>
          vue-recaptcha(
            v-if='isProduction'
            ref='invisibleRecaptcha'
            @verify='onVerify'
            @expired='onExpired'
            size='invisible'
            :sitekey='sitekey'
            badge='bottomleft')
          .pt-3
            .row.botonxs
              .col-12.col-sm-12.order-2.order-xs-1.text-center
                button.btn.btn-success.btn-md.btn-xs-block.font-weight-bold.claseboton
                  | {{ $t("components.navigation.submit") }}
                  font-awesome-icon.ml-3(
                    role='button'
                    focusable='false'
                    tabindex='-1'
                    icon='arrow-right')
              .col-12.col-sm-6.order-1.order-xs-2
</template>

<script>
// import Vue from 'vue';
import { mapGetters } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';
import { mapWaitingActions } from 'vue-wait';
import globalTypes from '@/store/types/global';
import solicitudTypes from '@/store/types/solicitud';

import departamentos from '@/data/departamentos.json';
import provincias from '@/data/provincias.json';
import distritos from '@/data/distritos.json';
import estadosCiviles from '@/data/estadosCiviles.json';
import operadoresTelefoniaMovil from '@/data/operadoresTelefoniaMovil.json';

export default {
  name: 'ValidacionInicial',
  dependencies: ['VUE_APP_reCAPTCHA_SITE_KEY', 'CommonService', 'SolicitudService'],
  components: { VueRecaptcha },
  data: () => ({
    form: {
      dni: null,
      estadoCivilId: null,
      departamentoId: null,
      provinciaId: null,
      distritoId: null,
      direccion: null,
      usoDeInformacionPersonal: null,
      numeroTelefonoMovil: null,
      operadorTelefoniaMovilId: null,
      correoElectronico: null,
      dniConyuge: null,
    },
    disabledDates: {
      from: new Date(),
    },
    formDisabled: {
      dni: true,
      others: true,
    },
    queryParams: null,
  }),
  computed: {
    sitekey() {
      return this.VUE_APP_reCAPTCHA_SITE_KEY;
    },
    departamentos() {
      return departamentos.map((d) => ({ value: d.id, text: d.name }));
    },
    provincias() {
      return provincias.filter((p) => p.departamentoId === this.form.departamentoId).map((d) => ({ value: d.id, text: d.name }));
    },
    distritos() {
      return distritos.filter((d) => d.provinciaId === this.form.provinciaId).map((d) => ({ value: d.id, text: d.name }));
    },
    estadosCiviles() {
      return estadosCiviles.map((d) => ({ value: d.id, text: d.name }));
    },
    ...mapGetters({
      isProduction: globalTypes.getters.isProduction,
    }),
    operadoresTelefoniaMovil() {
      operadoresTelefoniaMovil.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return operadoresTelefoniaMovil.map((d) => ({ value: d.id, text: d.name }));
    },
    labelConyugeOrConviviente() {
      if (this.hasConyuge) return this.$t('datosPersonales.form1.dniConyuge.label');
      if (this.hasConviviente) return this.$t('datosPersonales.form1.dniConyuge.label');
      return '\u0020';
    },
    hasConyuge() {
      return this.form.estadoCivilId === '950';
    },
    hasConviviente() {
      return this.form.estadoCivilId === '20078';
    },
  },
  created() {
    this.getQueryParams();
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
    // async provincias() {
    //   await Vue.nextTick();
    //   const { provinciaId } = this.form;
    //   this.form.provinciaId = null;
    //   await Vue.nextTick();
    //   this.form.provinciaId = provinciaId;
    // },
    // async distritos() {
    //   await Vue.nextTick();
    //   const { distritoId } = this.form;
    //   this.form.distritoId = null;
    //   await Vue.nextTick();
    //   this.form.distritoId = distritoId;
    // },
  },
  methods: {
    ...mapWaitingActions({
      goToBack: {
        action: solicitudTypes.actions.volver,
        loader: 'global',
      },
      dniChecker: {
        action: solicitudTypes.actions.dniChecker,
        loader: 'global',
      },
      cargaInicial: {
        action: solicitudTypes.actions.cargaInicial,
        loader: 'global',
      },
      validacionInicial: {
        action: solicitudTypes.actions.validacionInicial,
        loader: 'global',
      },
    }),
    changeDepartamento() {
      this.form.provinciaId = null;
      this.form.distritoId = null;
    },
    changeProvincia() {
      this.form.distritoId = null;
    },
    async fetchData(token = this.$route.query.ref) {
      if (token) {
        try {
          this.$wait.start('global');
          const data = await this.cargaInicial({ token });
          this.$log.debug('--->', data);
          if (data) {
            this.CommonService.assign(this.form, data);
            this.disableFields(true, false);
          } else {
            this.disableFields(false, true);
          }
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      } else {
        this.disableFields(false, true);
      }
    },
    async onDniChecker() {
      const dni = this.form.dni || '';
      if (dni.length === 8 && !this.formDisabled.dni) {
        this.disableFields(true, true);

        const { data, message } = await this.dniChecker({ dni, queryParams: this.queryParams });

        if (data === 0) {
          this.$swal({
            icon: 'warning',
            text: 'Solicita  su crédito vehicular Toyota en MAF',
            footer: message,
          }).then(() => {
            window.location.href = 'https://mafperu.com/simulador/';
          });
        } else {
          window.history.pushState(null, null, `/?ref=${data}&${this.$route.fullPath.substring(2)}`);
          this.fetchData(data);
        }
      }
    },
    disableFields(dni, others) {
      this.formDisabled = { dni, others };
    },
    async onSubmit() {
      if (this.formDisabled.dni) {
        const isValid = await this.$validator.validateAll('form1');
        if (isValid) {
          if (this.isProduction) {
            this.$refs.invisibleRecaptcha.execute();
          } else {
            this.onVerify('E2en7qAwGN');
          }
        }
      } else {
        this.onDniChecker();
      }
    },
    async onVerify(response) {
      try {
        const data = { ...this.form };
        const view = await this.validacionInicial(
          {
            ...data,
            reCAPTCHA: response,
            ...(this.queryParams && { queryParams: this.queryParams }), // envía solo si existe
          },
        );
        if (view) {
          this.$user.set({ role: 'REGISTERED' });
        }
      } finally {
        if (this.$refs.invisibleRecaptcha) {
          this.$refs.invisibleRecaptcha.reset();
        }
      }
    },
    onExpired() {
      this.$refs.invisibleRecaptcha.reset();
    },
    getQueryParams() {
      console.log('queryParams', this.$route.query);
      this.queryParams = this.$route.query;
    },
  },
};
</script>
<style>
.claseboton{
  border-radius: 3px 25px 3px 3px;
  height: 50px;
}
@media (max-width:620px) {
  .containerxs{
    margin-top: 300px;
    margin-bottom: 80px;
  }
  .claseboton{
  border-radius: 3px 25px 3px 3px;
  height: 50px;
  width: 100%;
  font-family: 'BebasNeuePro-Regular';
  }
  .botonxs{
    padding: 1.35rem;
    width: 100vw !important;
    border-top: solid 3px #EB0A1E !important;
    position: fixed;
    margin-top: 76px;
    bottom: 0 !important;
    left: 0;
    background-color: white;
    width: 100%;
    z-index: 100;
  }
}
</style>
