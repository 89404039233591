<!-- <template lang="pug">
  div
    Contactenos(:show.sync='showContactenos')
    nav.container-fluid.bg-secondary.text-center.text-white
      .row.align-items-center
        .col
          .d-flex.k-content-logo
            img.img-fluid(src='@/assets/header/logonuevo.png' style='width:120px; ')
        .col.k-show
          .d-flex.k-content.mt-2
            div.px-2
              img.img-fluid(src='@/assets/header/baseline-phone-24pxblack.png' width='24')
              p.m-0.pt-1.text-dark 01 6308 500
            div.px-2
              a(href='javascript:void(0)' @click='irContacto')
                img.img-fluid(src='@/assets/header/baseline-email-24pxblack.png' width='24')
              p.m-0.pt-1.text-dark Contáctanos
</template>

<script>
import Contactenos from '@/views/Modals/Contactenos.vue';

export default {
  name: 'KHeader',
  data: () => ({
    showContactenos: false,
  }),
  components: {
    Contactenos,
  },
  methods: {
    irTransparencia() {
      window.open('https://mafperu.com/informacion-al-usuario/#info-productos', '_self');
    },
    irLibroReclamaciones() {
      window.open('https://mafperu.com/wp-content/uploads/2016/05/Folleto-ATENCION-DE-REQUERIMIENTOS-Y-RECLAMOS-Ene16.pdf', '_blank');
    },
    irContacto() {
      window.open('https://mafperu.com/contactanos/');
    },
  },
};
</script>

<style lang="scss" scoped="">
nav {
  padding: 4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-shadow: 0 0 0 3px #ccc,
}

p {
  font-size: 0.6rem;
}

.k-content {
  justify-content: flex-end;
}

.k-content-logo {
  justify-content: flex-start;
}

@media only screen and (max-width:620px) {
  .k-show {
    display: none;
    visibility: hidden;
  }

  .k-content {
    justify-content: center;
  }

  .k-content-logo {
    justify-content: center;
  }
}
</style> -->
<template>
  <div class="headerxs">
    <nav class="container-fluid bg-secondary text-center text-white">
      <div class="row align-items-center">
        <div class="col">
          <div class="d-flex k-content-logo">
            <img
              class="img-fluid logonuevo"
              src="@/assets/header/logonuevo.png"
              alt=""
            />
          </div>
        </div>
        <div class="col k-show">
          <div class="d-flex k-content mt-2">
            <div class="px-2">
              <img
                class="img-fluid"
                src="@/assets/header/baseline-phone-24pxblack.png"
                width="24"
                alt=""
              />
              <p class="m-0 pt-1 text-dark">(01) 630-8500</p>
            </div>
            <div class="px-2" @click="openUrl('https://mafperu.com/contactanos/')" @keyup="noop">
              <img
                class="img-fluid"
                src="@/assets/header/baseline-email-24pxblack.png"
                width="24"
                alt=""
              />
              <p class="m-0 pt-1 text-dark">Contáctanos</p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'KHeader',
  methods: {
    openUrl(url) {
      window.open(url, '_blank');
    },
    noop() {
      // empty
    },
  },
};
</script>

<style lang="scss" scoped="">
  nav {
    padding: 1.35rem;
    box-shadow: 0px 3px 3px #ccc !important;
  }
  p {
    font-size: 0.6rem;
  }
  .k-content {
      justify-content: flex-end;
  }
  .k-content-logo {
      justify-content: flex-start;
  }
  .logonuevo{
      width: 120px;
  }
  @media only screen and (max-width:620px) {
    nav {
      padding: 0.8rem;
      box-shadow: 0px 3px 3px #ccc !important;
    }
    .k-show {
      display: none;
      visibility: hidden;
    }
    .k-content {
      justify-content: center;
    }
    .k-content-logo {
      justify-content: center;
    }
    .headerxs{
      position: fixed;
      top: 0 !important;
      left: 0;
      width: 100%;
      z-index: 100;
    }
    .logonuevo{
      height: 50px !important;
    }
  }
</style>
