<template lang="pug">
  .container.containerxs
    .row.justify-content-center
      .col-12.col-sm-12.col-md-10
        form(
          novalidate=''
          @submit.prevent='onSubmit'
          data-vv-scope='form1')
          .row
            .col.mt-3
              k-button-group(
                :label='$t("ingresos.form1.tipoIngresoId.label")'
                :error='errors.first("form1.tipoIngresoId")')
                k-button-radio(
                  :label='$t("ingresos.form1.tipoIngresoId.options[0].label")'
                  :data-vv-as='$t("ingresos.form1.tipoIngresoId.label")'
                  name='tipoIngresoId'
                  value='F'
                  class='tipoIngreso'
                  v-validate='"required"'
                  :error='errors.first("form1.tipoIngresoId")'
                  v-model='form.tipoIngresoId')
                k-button-radio(
                  :label='$t("ingresos.form1.tipoIngresoId.options[1].label")'
                  name='tipoIngresoId'
                  value='V'
                  class='tipoIngreso'
                  :error='errors.first("form1.tipoIngresoId")'
                  v-model='form.tipoIngresoId')
            .col.mt-3.pt-1.col-12
              k-input(
                :label='$t("ingresos.form1.ingresoBrutoMensual1.label")'
                :placeholder='$t("ingresos.form1.ingresoBrutoMensual1.placeholder")'
                :data-vv-as='$t("ingresos.form1.ingresoBrutoMensual1.label")'
                name='ingresoBrutoMensual1'
                maxlength='7'
                v-validate='"required|numeric"'
                :error='errors.first("form1.ingresoBrutoMensual1")'
                v-model.number='form.ingresoBrutoMensual1')
          .row(v-if='form.tipoIngresoId === "V"')
            .col.col-12.mt-3
              k-input(
                :label='$t("ingresos.form1.ingresoBrutoMensual2.label")'
                :placeholder='$t("ingresos.form1.ingresoBrutoMensual2.placeholder")'
                :data-vv-as='$t("ingresos.form1.ingresoBrutoMensual2.label")'
                name='ingresoBrutoMensual2'
                maxlength='7'
                v-validate='"required|numeric"'
                :error='errors.first("form1.ingresoBrutoMensual2")'
                v-model.number='form.ingresoBrutoMensual2')
            .col.col-12.mt-3.mb-5
              k-input(
                :label='$t("ingresos.form1.ingresoBrutoMensual3.label")'
                :placeholder='$t("ingresos.form1.ingresoBrutoMensual3.placeholder")'
                :data-vv-as='$t("ingresos.form1.ingresoBrutoMensual3.label")'
                name='ingresoBrutoMensual3'
                maxlength='7'
                v-validate='"required|numeric"'
                :error='errors.first("form1.ingresoBrutoMensual3")'
                v-model.number='form.ingresoBrutoMensual3')
          .pt-3
            .row.mt-3.botonxs
              .col-7.col-sm-7.order-2.text-left.px-1
                button.btn.btn-success.btn-md.btn-xs-block.clasebotonIngresos(type='submit') {{ $t('components.navigation.submit') }}
                  font-awesome-icon.ml-1(
                      role='button'
                      focusable='false'
                      tabindex='-1'
                      icon='arrow-right')
              .col-5.col-sm-5.order-1.text-right
                button.btn.btn-plomo.btn-md.btn-xs-block.clasebotonIngresos(
                  @click='goToBack'
                  type='button')
                  font-awesome-icon.ml-1(
                    role='button'
                    focusable='false'
                    tabindex='-1'
                    icon='arrow-left')
                  |  {{ $t('components.navigation.return') }}
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import solicitudTypes from '@/store/types/solicitud';

export default {
  name: 'Ingresos',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        tipoIngresoId: null,
        ingresoBrutoMensual1: null,
        ingresoBrutoMensual2: null,
        ingresoBrutoMensual3: null,
      },
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    ...mapWaitingActions({
      goToBack: {
        action: solicitudTypes.actions.volver,
        loader: 'global',
      },
    }),
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getIngresos();
        if (data) {
          this.form.tipoIngresoId = data.tipoIngresoId;
          const ingresos = data.ingresos ? JSON.parse(data.ingresos) : {};
          this.form.ingresoBrutoMensual1 = ingresos.ingresoBrutoMensual1 || null;
          this.form.ingresoBrutoMensual2 = ingresos.ingresoBrutoMensual2 || null;
          this.form.ingresoBrutoMensual3 = ingresos.ingresoBrutoMensual3 || null;
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async onSubmit() {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        try {
          this.$wait.start('global');
          const data = {};
          data.tipoIngresoId = this.form.tipoIngresoId;
          const ingresos = {
            ingresoBrutoMensual1: this.form.ingresoBrutoMensual1,
          };
          if (this.form.tipoIngresoId === 'V') {
            ingresos.ingresoBrutoMensual2 = this.form.ingresoBrutoMensual2;
            ingresos.ingresoBrutoMensual3 = this.form.ingresoBrutoMensual3;
          }
          data.ingresos = JSON.stringify(ingresos);
          const result = await this.SolicitudService.putIngresos(data);
          this.$store.commit(solicitudTypes.mutations.setView, result.view);
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      }
    },
  },
};
</script>
<style>
.clasebotonIngresos{
  border-radius: 3px 25px 3px 3px;
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
}
@media (max-width:620px) {
  .containerxs{
    margin-top: 300px;
    margin-bottom: 80px;
  }
}
</style>
