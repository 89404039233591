<template lang="pug">
  .container.containerxsVisiConceseonario
    .row
      .col-12.col-md-8.offset-md-2.mt-4
        //- h5.mt-3.mb-5.text-center.text-dark {{ $t('visitaConcesionario.title') }}
        form(
          novalidate=''
          @submit.prevent='onSubmit'
          data-vv-scope='form1')
          .row
            .col-12.col-sm-12.col-md-12.col-lg-4.mb-2
              k-input(
                :disabled='true'
                :label='$t("visitaConcesionario.form1.concesionarioVisita.label")'
                :data-vv-as='$t("visitaConcesionario.form1.concesionarioVisita.label")'
                name='concesionarioVisita'
                v-validate='"required"'
                :error='errors.first("form1.concesionarioVisita")'
                v-model.string='form.concesionarioVisita'
                )
            .col-12.col-sm-12.col-md-12.col-lg-4.mt-2.mt-md-0.pt-1.pt-md-0
              k-date-picker.datePicker(
                :label='$t("visitaConcesionario.form1.fechaVisita.label")'
                :placeholder='$t("visitaConcesionario.form1.fechaVisita.placeholder")'
                :data-vv-as='$t("visitaConcesionario.form1.fechaVisita.label")'
                name='fechaVisita'
                maxlength='10'
                v-validate='"required|date_format:yyyy-MM-dd|checkDate"'
                :error='errors.first("form1.fechaVisita")'
                v-model='form.fechaVisita')
            .col-12.col-sm-12.col-md-12.col-lg-4
              k-select(
                :label='$t("visitaConcesionario.form1.horaVisita.label")'
                :data-vv-as='$t("visitaConcesionario.form1.horaVisita.label")'
                name='horaVisita'
                v-validate='"required"'
                :error='errors.first("form1.horaVisita")'
                v-model.string='form.horaVisita'
                :options='horarios'
                @change='form.horaVisita = $event')
          .pt-5
            .row.botonxs
              .col-12.col-sm-12.offset-sm-12.order-2.order-xs-1.text-center
                button.btn.btn-success.btn-md.btn-xs-block.claseboton.px-4(
                  type='submit') {{ $t('components.navigation.submit') }}
                  font-awesome-icon.ml-3(
                    role='button'
                    focusable='false'
                    tabindex='-1'
                    icon='chevron-right')
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import solicitudTypes from '@/store/types/solicitud';
import sucursales from '@/data/sucursales.json';
import concesionarios from '@/data/concesionarios.json';
import moment from 'moment-timezone';
import { Validator } from 'vee-validate';

export default {
  name: 'VisitaConcesionario',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        concesionarioVisita: null,
        fechaVisita: null,
        horaVisita: null,
        endTime: null,
        horarios: [],
      },
      disabledDates: {
        to: new Date(),
      },
    };
  },
  computed: {
    sucursales() {
      return sucursales.filter((p) => p.concesionarioId === this.form.concesionarioId && p.id === this.form.sucursalId.toString()).map((d) => ({ value: d.id, text: d.name }));
    },
    horarios() {
      return this.form.horarios;
    },
  },
  created() {
    Validator.extend('checkDate', {
      getMessage: (field, params, data) => data.message,
      validate: async () => {
        try {
          this.$wait.start('global');
          const today = moment().format('YYYY-MM-DD');
          const fecVisita = moment().format(this.form.fechaVisita);
          if (fecVisita < today) {
            return { valid: false, data: { message: ' La Fecha de visita a concesionario debe ser mayor o igual a hoy' } };
          }

          const dow = moment(this.form.fechaVisita).weekday();

          if (dow === 0) {
            return { valid: false, data: { message: 'No hay horario de atención en la fecha seleccionada' } };
          }

          if (dow === 6) {
            this.form.endTime = 13;
          } else {
            this.form.endTime = 18;
          }

          if (dow) {
            const horario = [];
            const x = 60; // minutes interval
            const times = []; // time array
            let tt = 8 * 60; // start time
            const ft = this.form.endTime * 60;
            const ap = ['AM', 'PM']; // AM-PM

            // loop to increment the time and push results in array
            for (let i = 0; tt < ft; i++) {
              const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
              const hh1 = Math.floor(tt / 60) + 1; // getting hour of day + 1
              const mm = (tt % 60); // getting minutes of the hour in 0-55 format
              const time1 = `${(hh1 === 12 ? '12' : (`0${hh1 % 12}`)).slice(-2)}:${(`0${mm}`).slice(-2)}${ap[Math.floor(hh1 / 12)]}`;
              times[i] = `${(hh === 12 ? '12' : (`0${hh % 12}`)).slice(-2)}:${(`0${mm}`).slice(-2)}${ap[Math.floor(hh / 12)]} - ${time1}`; // pushing data in array in [00:00 - 12:00 AM/PM format]
              horario.push({ value: times[i], text: times[i] });
              tt += x;
            }

            this.form.horarios = horario;
          }
          return { valid: true };
        } catch (err) {
          return { valid: false, data: { message: err.message } };
        } finally {
          this.$wait.end('global');
        }
      },
    }, {
      immediate: true,
    });

    Validator.extend('checkHours', {
      getMessage: (field, params, data) => data.message,
      validate: async () => {
        try {
          this.$wait.start('global');
          // eslint-disable-next-line prefer-regex-literals
          const reg = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
          const hourText = this.form.horaVisita;

          const check = hourText.replace(reg, '');
          if (check !== '') {
            return { valid: false, data: { message: 'El formato de la hora no es el correcto. El formato permitido es 24H:MM' } };
          }
          return { valid: true };
        } catch (err) {
          return { valid: false, data: { message: err.message } };
        } finally {
          this.$wait.end('global');
        }
      },
    }, {
      immediate: true,
    });
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    ...mapWaitingActions({
      goToBack: {
        action: solicitudTypes.actions.volver,
        loader: 'global',
      },
    }),
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getVisitaConcesionario();
        if (data) {
          this.CommonService.assign(this.form, data);
        }

        const { text: concesionarioText } = concesionarios.filter((p) => p.id === data.concesionarioId.toString()).map((d) => ({ value: d.id, text: d.name }))[0];

        const { text: sucursalText } = sucursales.filter((p) => p.concesionarioId === data.concesionarioId && p.id === data.sucursalId.toString()).map((d) => ({ value: d.id, text: d.name }))[0];

        const concesionarioSucursal = `${concesionarioText} / ${sucursalText}`;
        this.form.concesionarioVisita = concesionarioSucursal;
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async onSubmit() {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        try {
          this.$wait.start('global');
          const data = { ...this.form };
          delete data.endTime;
          delete data.horarios;
          const result = await this.SolicitudService.putVisitaConcesionario(data);
          this.$store.commit(solicitudTypes.mutations.setView, result.view);
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      }
    },
  },
};
</script>
<style>
@media (max-width:620px) {
  .containerxsVisiConceseonario{
    margin-top: 300px;
    margin-bottom: 80px;
  }
}
</style>
