<!-- <template lang="pug">
  .container-fluid
    .row
      .col
        ol.steps.list-unstyled
          li.step-item(v-for='(label, index) in steps')
            .step-item-wrapper
              div.circle(:class=`{
                current: index === step,
                complete: index < step,
                pending: index > step,
                cero: step === 0,
                uno: step === 1,
                dos: step === 2,
                tres: step === 3,
              }`)
                span
              p.m-0.pt-0.text.text-center {{ label }}
    h4.text-center.text-secondary.bg-primary.font-weight-bold.py-4 {{ $t('validacionInicial.title') }}
</template>

<script>
export default {
  name: 'KStepper',
  inheritAttrs: false,
  props: {
    steps: Array,
    step: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = `${this._uid}`;
  },
};
</script>

<style lang="scss">
  $success: #ed1c24;
  $primary: #EB0A1E;
  $default: #c1c1c1;
  $default-text: #495057;
  .steps.list-unstyled {
    background-color: #E9EAE8;
    flex: auto;
    margin:auto;
    width: 70%;
    border-radius: 10px 10px 0px 0px;
  }
  h4{
    background-color: #E9EAE8;
    flex: auto;
    margin:auto;
    width: 70%;
    border-radius: 0px 0px 50px 50px;
  }
  .steps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .step-item {
      padding-top: 40px;
      padding-bottom: 45px;
      display: flex;
      flex-direction: row;
      &:after {
        content: '\00a0';
        height: 2px;
        min-width: 40px;
        background-color: transparent;
        align-self: center;
      }
      &:last-child:after {
        content: '';
        height: 0;
        min-width: 0;
      }
      .step-item-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .circle {
          border-radius: 50%;
          border: 7px solid $default;
          display: flex;
          align-items: center;
          justify-content: center;
          &.complete {
            width: 25px;
            height: 25px;
            margin: 12.5px;
            border: 3px solid $success;
            span {
              display: block;
              width: 5px;
              height: 10px;
              border: solid $success;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
            & + p {
              color: $success;
            }
          }
          &.current {
            width: 50px;
            height: 50px;
            border: 0;
            background-image: url('../assets/stepper/sedanrojo.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            span {
              display: flex;
            }
            & + p {
              color: $primary;
            }
          }
          &.pending {
            width: 17px;
            height: 17px;
            margin: 16.5px;
            border: 3px solid $default;
            background-color: #E9EAE8
            & + p {
              color: $default-text;
            }
            &.cero {
              width: 50px;
              height: 50px;
              border: 0;
              background-image: url('../assets/stepper/sedanrojo.png') !important;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
            &.uno {
              width: 50px;
              height: 50px;
              border: 0;
              background-image: url('../assets/stepper/sedanrojo.png') !important;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
            &.dos {
              width: 50px;
              height: 50px;
              border: 0;
              background-image: url('../assets/stepper/sedanrojo.png') !important;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
            &.tres {
              width: 50px;
              height: 50px;
              border: 0;
              background-image: url('../assets/stepper/sedanrojo.png') !important;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
          }
        }
      }
      .text {
        font-size: 0.6rem;
      }
    }
  }
</style> -->
<template lang="pug">
  .container-fluid.mt-md-4.px-0.steperxs
    .row(style="justify-content: center;")
      .col
        ol.steps.list-unstyled
          li.step-item(v-for='(label, index) in steps')
            .step-item-wrapper
              div.circle(:class=`{
                current: index === step || step === 4 || step === 5 || step === 6,
                complete: index < step && index < 3,
                pending: index > step,
                cero: index === 0,
                uno: index === 1,
                dos: index === 2,
                tres: index === 3 || step === 4 || step === 5 || step === 6,
              }`)
                div
                  span
              p.m-0.pt-1.text.text-center.bebasNeueProStepperTitle  {{ label  }}
    h4.text-center.text-secondary.bg-primary.font-weight-bold.py-4.bebasNeueProStepper(v-if='step === 0') {{ this.view === 'InformacionCotizacion' ? 'Aquí la información de tu vehículo cotizado con las condiciones seleccionadas' : '¡Empecemos. Valida tus datos ingresados, para continuar con la solicitud de crédito' }}
    h4.text-center.text-secondary.bg-primary.font-weight-bold.py-4.bebasNeueProStepper(v-if='step === 1') {{ 'Ahora, verifica tus datos laborales' }}
    h4.text-center.text-secondary.bg-primary.font-weight-bold.py-4.bebasNeueProStepper(v-if='step === 2') {{ this.view === 'Ingresos' ? 'Ahora, verifica tus datos laborales' :'¡Llegó el momento! Cotiza tu auto y calcula la cuota más apropiada para ti' }}
    h4.text-center.text-secondary.bg-primary.font-weight-bold.py-4.bebasNeueProStepper(v-if='step === 3')
      span ¡Un paso más para completar tu evaluación!
      br
      span.PinSubTitleSize Te enviaremos un mensaje SMS con un código de seguridad de cuatro dígitos
    h4.text-center.text-secondary.bg-primary.font-weight-bold.py-3.bebasNeueProStepper(v-if='step === 4')
      span Adjunta aquí los siguientes documentos en formato PNG, JPG, JPEG o PDF.
      br
      span.PinSubTitleSize2 Tamaño maximo de archivos: 5MB.
      h5.PinSubTitleSize2 Recuerda revisar que los documentos son correctos y legibles
    h4.text-center.text-secondary.bg-primary.font-weight-bold.py-4.bebasNeueProStepper(v-if='step === 5') {{ 'Pre-documentos' }}
    h4.text-center.text-secondary.bg-primary.font-weight-bold.py-4.bebasNeueProStepper(v-if='step === 6') {{ 'Para terminar, valida el concesionario y la fecha de visita para mostrarte las bondades de tu auto' }}
</template>

<script>
import { mapGetters } from 'vuex';
import solicitudTypes from '@/store/types/solicitud';

export default {
  name: 'KStepper',
  inheritAttrs: false,
  props: {
    steps: Array,
    step: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = `${this._uid}`;
  },
  computed: {
    ...mapGetters({
      view: solicitudTypes.getters.getView,
    }),
  },
};
</script>

<style lang="scss">
  $success: #ed1c24;
  $primary: #EB0A1E;
  $default: #c1c1c1;
  $dark: #4A4A4A;
  $claro: #fff;
  $default-text: #495057;
  .steperxs{
    margin-top: 12px;
  }
  .steps.list-unstyled {
    background-color: #E9EAE8;
    flex: auto;
    margin:auto;
    width: 85%;
    border-radius: 10px 10px 0px 0px;
    max-width: 1200px;
  }
  .bebasNeueProStepper{
    font-size: 28px;
    font-family: 'BebasNeuePro-Bold';
    padding-left: 40px;
    padding-right: 40px;
  }
  .bebasNeueProStepperTitle{
    font-size: 16px !important;
    font-family: 'BebasNeuePro-Bold';
  }
  .PinSubTitleSize{
    line-height: 1;
    font-size: 18px;
  }
  .PinSubTitleSize2{
    line-height: 0.8;
    font-size: 16px;
  }
  h4{
    background-color: #E9EAE8;
    flex: auto;
    margin:auto;
    width: 85%;
    border-radius: 0px 0px 50px 50px;
    max-width: 1200px;
  }
  p{
    width: 97px;
  }
  .steps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .step-item {
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
      flex-direction: row;
      &:after {
        content: '\00a0';
        height: 2px;
        min-width: 10vw;
        margin-left: -16px;
        margin-right: -16px;
        margin-top: -50px;
        background-color: transparent;
        align-self: center;
        border: solid 1px $default;
      }
      &:last-child:after {
        content: '';
        visibility: hidden;
        height: 0;
        min-width: 0;
      }
      .step-item-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .circle {
          border-radius: 50%;
          border: 7px solid $default;
          display: flex;
          align-items: center;
          justify-content: center;
          &.complete {
            width: 64px;
            height: 64px;
            border: 3px solid $dark;
            div{
              width: 50px;
              height: 50px;
              border-radius: 50%;
              background-color: $dark;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            span {
              display: block;
              width: 14px;
              height: 22px;
              border: solid $claro;
              border-width: 0 5px 5px 0;
              transform: rotate(45deg);
            }
            & + p {
              color: $default-text;
              font-size: 16px;
            }
          }
          &.current {
            width: 64px;
            height: 64px;
            border: 0px solid $dark;
            // background-image: url('../assets/stepper/stepdatos-personales.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            span {
              display: flex;
            }
            & + p {
              color: $default-text;
              font-size: 12px;
            }
            &.cero {
              background-image: url('../assets/stepper/stepdatos-personales.svg');
            }
            &.uno {
              background-image: url('../assets/stepper/step-datoslaborales.svg');
            }
            &.dos {
              background-image: url('../assets/stepper/step-datos-financiamiento.svg');
            }
            &.tres {
              background-image: url('../assets/stepper/step-datos-complementarios.svg');
            }
          }
          &.pending {
            width: 64px;
            height: 64px;
            border: 3px solid $dark;
            // border: 2px solid $default;
            background-color: #fff;
            & + p {
              color: #ccc;
              font-size: 12px;
            }
            &.cero {
              width: 64px;
              height: 64px;
              border: 0;
              background-image: linear-gradient(to right,rgba(233, 234, 232, 0.8), rgba(233, 234, 232, 0.8)),  url('../assets/stepper/stepdatos-personales.svg');
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
            &.uno {
              width: 64px;
              height: 64px;
              border: 0;
              background-image:  linear-gradient(to right,rgba(233, 234, 232, 0.8), rgba(233, 234, 232, 0.8)), url('../assets/stepper/step-datoslaborales.svg');
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
            &.dos {
              width: 64px;
              height: 64px;
              border: 0;
              // background-image: url('../assets/stepper/step-datos-financiamiento.svg');
              background-image: linear-gradient(to right,rgba(233, 234, 232, 0.8), rgba(233, 234, 232, 0.8)), url('../assets/stepper/step-datos-financiamiento.svg');
              background-repeat: no-repeat;
              background-image: -o-linear-gradient();
              background-size: cover;
              background-position: center;
            }
            &.tres {
              width: 64px;
              height: 64px;
              border: 0;
              background-image: linear-gradient(to right,rgba(233, 234, 232, 0.8), rgba(233, 234, 232, 0.8)),  url('../assets/stepper/step-datos-complementarios.svg');
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
          }
        }
      }
      .text {
        font-size: 0.6rem;
      }
    }
  }
@media (max-width:620px) {
  .steperxs{
    position: fixed;
    margin-top: 74px;
    top: 0 !important;
    left: 0;
    width: 100%;
    z-index: 100;
  }
  .steps.list-unstyled {
    background-color: #E9EAE8;
    flex: auto;
    padding-top: 13px;
    padding-bottom: 13px;
    width: 100%;
    border-radius: 0px 0px 0px 0px;
    max-width: 620px;
  }
  .bebasNeueProStepper{
    line-height: 1;
    font-size: 18px;
    font-family: 'BebasNeuePro-Bold';
    padding-left: 40px;
    padding-right: 40px;
  }
  .bebasNeueProStepperTitle{
    font-size: 12px !important;
    font-family: 'BebasNeuePro-Bold';
  }
  h4{
    // background-color: #E9EAE8;
    flex: auto;
    margin:auto;
    width: 100%;
    border-radius: 0px 0px 50px 50px;
    max-width: 1200px;
  }
  p{
    width: 97px;
  }
  .PinSubTitleSize{
    line-height: 1;
    font-size: 18px;
  }
  .PinSubTitleSize2{
    line-height: 0.8;
    font-size: 14px;
  }
  .steps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .step-item {
      padding-top: 2px;
      padding-bottom: 2px;
      display: flex;
      flex-direction: row;
      &:after {
        content: '\00a0';
        height: 2px;
        min-width: 10vw;
        margin-left: -24px;
        margin-right: -24px;
        margin-top: -40px;
        background-color: transparent;
        align-self: center;
        border: solid 1px $default;
      }
      &:last-child:after {
        content: '';
        visibility: hidden;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        height: 0;
        min-width: 0;
      }
      .step-item-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .circle {
          border-radius: 50%;
          border: 7px solid $default;
          display: flex;
          align-items: center;
          justify-content: center;
          &.complete {
            width: 50px;
            height: 50px;
            border: 3px solid $dark;
            div{
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: $dark;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            span {
              display: block;
              width: 12px;
              height: 20px;
              border: solid $claro;
              border-width: 0 5px 5px 0;
              transform: rotate(45deg);
            }
            & + p {
              color: $default-text;
              font-size: 12px;
            }
          }
          &.current {
            width: 50px;
            height: 50px;
            border: 0px solid $dark;
            // background-image: url('../assets/stepper/stepdatos-personales.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            span {
              display: flex;
            }
            & + p {
              color: $default-text;
              font-size: 12px;
            }
            &.cero {
              background-image: url('../assets/stepper/stepdatos-personales.svg');
            }
            &.uno {
              background-image: url('../assets/stepper/step-datoslaborales.svg');
            }
            &.dos {
              background-image: url('../assets/stepper/step-datos-financiamiento.svg');
            }
            &.tres {
              background-image: url('../assets/stepper/step-datos-complementarios.svg');
            }
          }
          &.pending {
            width: 50px;
            height: 50px;
            border: 3px solid $dark;
            // border: 2px solid $default;
            background-color: #fff;
            & + p {
              color: #ccc;
              font-size: 12px;
            }
            &.cero {
              width: 50px;
              height: 50px;
              border: 0;
              background-image: linear-gradient(to right,rgba(233, 234, 232, 0.8), rgba(233, 234, 232, 0.8)),  url('../assets/stepper/stepdatos-personales.svg');
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
            &.uno {
              width: 50px;
              height: 50px;
              border: 0;
              background-image:  linear-gradient(to right,rgba(233, 234, 232, 0.8), rgba(233, 234, 232, 0.8)), url('../assets/stepper/step-datoslaborales.svg');
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
            &.dos {
              width: 50px;
              height: 50px;
              border: 0;
              // background-image: url('../assets/stepper/step-datos-financiamiento.svg');
              background-image: linear-gradient(to right,rgba(233, 234, 232, 0.8), rgba(233, 234, 232, 0.8)), url('../assets/stepper/step-datos-financiamiento.svg');
              background-repeat: no-repeat;
              background-image: -o-linear-gradient();
              background-size: cover;
              background-position: center;
            }
            &.tres {
              width: 50px;
              height: 50px;
              border: 0;
              background-image: linear-gradient(to right,rgba(233, 234, 232, 0.8), rgba(233, 234, 232, 0.8)),  url('../assets/stepper/step-datos-complementarios.svg');
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
          }
        }
      }
      .text {
        font-size: 0.6rem;
      }
    }
  }
}
</style>
