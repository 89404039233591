<template lang="pug">
  k-center(
    :full='true'
    :backdrop='true'
    :incremental='true')
    .card.rounded-0.bg-light
      .card-header.border-0.rounded-0.bg-light.d-flex.pt-3
        img.ml-auto.k-cursor-pointer(
          src='@/assets/images/Icon_X_Popup.svg'
          width='15rem'
          @click='$emit("close", $event)')
      .card-body.pt-0.px-3.pb-3.k-scrollbar
        slot
</template>

<script>
export default {
  name: 'KModal',
  inheritAttrs: false,
  props: {
    title: String,
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
  },
  methods: {
    hasSlot(name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
};
</script>

<style lang="scss" scoped="">
  .k-scrollbar{
    overflow-y: auto;
  }
  @media only screen and (max-width:620px) {
    .k-scrollbar{
      overflow-y: auto;
      height: 80vh
    }
  }
</style>
