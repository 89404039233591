<!-- <template lang="pug">
  .form-group.position-relative
    .clearfix.position-relative
      label.float-left(:for='id' v-text='label')
      span.float-right.mt-1(
        v-if='info'
        ref='info'
        data-toggle='tooltip'
        data-placement='top'
        :title='info')
        font-awesome-icon(
          role='button'
          focusable='false'
          tabindex='-1'
          icon='info-circle')
    input.form-control(
      ref='file'
      type='file'
      :accept='accept'
      @change='onFileChange($event)'
      autocomplete='off'
      v-show='false')
    button.btn.form-control.text-left(
      :id='id'
      :class='{ "btn-outline-primary": !isUp, "btn-success": isUp }'
      type='button'
      @click='onProxyFileUpload') {{ isUp ? $t('components.upload.uploaded') : $t('components.upload.pending') }}&nbsp;&nbsp;
      font-awesome-icon.k-upload-icon(icon='check-circle' v-if='isUp')
      font-awesome-icon.k-upload-icon(icon='cloud-upload-alt' v-else)
</template>

<script>
import $ from 'jquery';

function getFilePathExtension(path) {
  const filename = path.split('\\').pop().split('/').pop();
  return filename.substr((Math.max(0, filename.lastIndexOf('.')) || Infinity) + 1);
}

export default {
  name: 'KUpload',
  inheritAttrs: false,
  props: {
    label: String,
    accept: {
      type: String,
      required: true,
    },
    isUp: {
      type: Boolean,
      default: false,
    },
    info: String,
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
    if (this.info) {
      $(this.$refs.info).tooltip();
    }
  },
  methods: {
    onProxyFileUpload() {
      this.$refs.file.click();
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const fileExtension = getFilePathExtension(files[0].name).toUpperCase();
      if (this.accept) {
        const accept = this.accept.toUpperCase();
        if (accept.indexOf(fileExtension) !== -1) {
          this.$emit('onFileChange', files[0]);
        } else {
          this.$swal({
            icon: 'warning',
            title: 'Oops...',
            text: 'Archivo no v\u00E1lido',
          });
        }
      }
    },
  },
};
</script>

<style type="text/css" scoped="">
  .k-upload-icon {
    float: right;
    position: relative;
    top: 0.25em;
  }
</style> -->

<template lang="pug">
  .form-group.position-relative
    .clearfix.position-relative
      label.float-left.uploadLabel(:for='id' v-text='label')
      span.float-right.mt-1(
        v-if='info'
        ref='info'
        data-toggle='tooltip'
        data-placement='top'
        :title='info')
        font-awesome-icon(
          role='button'
          focusable='false'
          tabindex='-1'
          icon='fa-solid fa-info-circle')
    input.form-control(
      ref='file'
      type='file'
      :accept='accept'
      @change='onFileChange($event)'
      autocomplete='off'
      v-show='false')
    button.btn.form-control.pt-4.pb-5(
      :id='id'
      :class='{ "btnStyle text-center": !isUp, "btnStyle2 text-left": isUp }'
      type='button'
      @click='onProxyFileUpload') {{ isUp ? 'Archivo cargado exitosamente' : 'Examinar o simplemente arrastra tu archivo aquí' }}
      font-awesome-icon.k-upload-icon(icon='fa fa-check-circle' v-if='isUp')
      font-awesome-icon.k-upload-icon2(icon='fa fa-cloud-arrow-up' v-else)
      <img class="k-upload-icon3" src="@/assets/images/img_up.png" alt="" v-if="isUp"/>
</template>

<script>
import $ from 'jquery';

function getFilePathExtension(path) {
  const filename = path.split('\\').pop().split('/').pop();
  return filename.substr((Math.max(0, filename.lastIndexOf('.')) || Infinity) + 1);
}

export default {
  name: 'KUpload',
  inheritAttrs: false,
  props: {
    label: String,
    accept: {
      type: String,
      required: true,
    },
    isUp: {
      type: Boolean,
      default: false,
    },
    info: String,
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
    if (this.info) {
      $(this.$refs.info).tooltip();
    }
  },
  methods: {
    onProxyFileUpload() {
      this.$refs.file.click();
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const fileExtension = getFilePathExtension(files[0].name).toUpperCase();
      if (this.accept) {
        const accept = this.accept.toUpperCase();
        if (accept.indexOf(fileExtension) !== -1) {
          this.$emit('onFileChange', files[0]);
        } else {
          this.$swal({
            type: 'warning',
            title: 'Oops...',
            text: 'Archivo no v\u00E1lido',
          });
        }
      }
    },
  },
};
</script>

<style type="text/css" scoped="">
  .k-upload-icon {
    position: absolute;
    top: 40px;
    left: 90px;
    color: #EB0A1E;
    font-size: 20px;
  }
  .k-upload-icon2 {
    position: absolute;
    top: 30px;
    left: 48%;
  }
  .k-upload-icon3 {
    position: absolute;
    top: 15px;
    left: 3%;
    height: 70px;
  }
  .uploadLabel{
    font-size: 14px;
    font-family: 'BebasNeuePro-expBold';
  }
  .btnStyle{
    background-color: #E9EAE8;
    position: relative;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    border: 2px dashed #cccccc;
    font-family: 'BebasNeuePro-expBold' !important;
    color: #cccccc;
  }
  .btnStyle2{
    background-color: #E9EAE8;
    position: relative;
    padding-top: 40px !important;
    padding-bottom: 60px !important;
    border: 2px solid #cccccc;
    padding-left: 120px;
    font-family: 'BebasNeuePro-expBold' !important;
  }
</style>
