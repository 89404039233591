<template lang="pug">
  .form-group.position-relative.clasegap.mt-2
    .clearfix.position-relative.prettyLabel2
      label.float-left(:for='id' v-text='label')
      span {{ label + label+ label+label+label+label+label+label+label+label+label+label+label }}
      span.float-right.mt-1(
        v-if='info'
        ref='info'
        data-toggle='tooltip'
        data-placement='top'
        :title='info')
        font-awesome-icon(
          role='button'
          focusable='false'
          tabindex='-1'
          icon='info-circle')
    select.custom-select.custom-select-md.optionCustom(
      :id='id'
      :name='name'
      autocomplete='off'
      :value='value'
      :disabled='disabled'
      @input='$emit("input", $event.target.value)'
      @change='$emit("change", $event.target.value)'
      :class='{"is-invalid": error}')
      option.optionCustom(:value='null') Seleccionar
      option.optionCustom(
        v-for='option in options'
        :value='option.value'
        v-text='option.text')
    .invalid-tooltip(v-if='error' v-text='error')
</template>

<script>
import $ from 'jquery';

export default {
  name: 'KSelect',
  inheritAttrs: false,
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
    validator: 'new',
  },
  props: {
    value: {},
    name: String,
    label: String,
    error: String,
    options: Array,
    info: String,
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
    if (this.info) {
      $(this.$refs.info).tooltip();
    }
  },
};
</script>
<style lang="scss" scoped="">
select{
  height: 54px;
}

.prettyLabel2{
  position: relative;
}
.prettyLabel2 label{
  position: absolute;
  pointer-events: none;
  padding-left: 2px;
  font-family: 'BebasNeuePro-Regular';
  padding-right: 2px;
  margin-top: -13px;
  margin-left: 10px;
  font-size: 16px;
  background-color: transparent;
  transition: 0.5s;
  z-index: 20;
  color: #000;
}
// .prettyLabel2 select:disabled ~ span{
//   position: absolute;
//   pointer-events: none;
//   font-size: 18px;
//   padding: 0px;
//   color: red;
//   margin-top: 10px;
//   height: 1px  !important;
//   background-color: red;
// }
.prettyLabel2 span{
  position: absolute;
  pointer-events: none;
  font-size: 1px;
  padding-right: 5px;
  margin-top: -4px;
  color: #fff;
  padding-left: 5px;
  margin-left: 10px;
  height: 5px !important;
  background-color: #fff;
  z-index: 10;
}
.optionCustom{
  font-family: 'BebasNeuePro-Regular';
  font-size: 16px;
}
</style>
