<template lang="pug">
  .container
    .row
      .col-12.col-md-6.offset-md-3
        form(
          novalidate=''
          @submit.prevent='onSubmit'
          data-vv-scope='form1')
          .row
            .col.text-center.mt-3
              img.img-fluid.mb-3.w-75.mt-5(
                v-if='form.modelo'
                :src="`/modelos/${form.modelo}.png`")
              p.h4.text-dark.font-weight-bold {{ $t('visualizacionModelo.hello') }} {{ firstName }},
              p.h4.text-dark {{ $t('visualizacionModelo.subtitle') }}
          .pt-3
            .row
              .col-12.col-sm-6.order-2.order-xs-1.text-right
                button.btn.btn-success.btn-md.btn-xs-block.font-weight-bold
                  | {{ $t("components.navigation.submit") }}
                  font-awesome-icon.ml-3(
                    role='button'
                    focusable='false'
                    tabindex='-1'
                    icon='chevron-right')
              .col-12.col-sm-6.order-1.order-xs-2
                button.btn.btn-success.btn-md.btn-xs-block.font-weight-bold(
                  @click='goToBack'
                  type='button')
                  font-awesome-icon.mr-3(
                    role='button'
                    focusable='false'
                    tabindex='-1'
                    icon='chevron-left')
                  | {{ $t("components.navigation.return") }}
</template>

<script>
import { mapGetters } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import solicitudTypes from '@/store/types/solicitud';

export default {
  name: 'VisualizacionModelo',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        modelo: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      firstName: solicitudTypes.getters.getFirstName,
    }),
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    ...mapWaitingActions({
      goToBack: {
        action: solicitudTypes.actions.volver,
        loader: 'global',
      },
    }),
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getVisualizacionModelo();
        if (data) {
          this.CommonService.assign(this.form, data);
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async onSubmit() {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        try {
          this.$wait.start('global');
          const data = {};
          const result = await this.SolicitudService.putVisualizacionModelo(data);
          this.$store.commit(solicitudTypes.mutations.setView, result.view);
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      }
    },
  },
};
</script>
