<template lang="pug">
  .container.containerxsDoc
    .row
      .col-12.col-md-10.offset-md-1
        //- h5.mt-3.mb-0.text-center.text-dark {{ $t('documentos.title') }}
        //- h6.mt-3.text-center.text-dark {{ $t('documentos.notice1') }}
        //- h6.mb-5.text-center.text-dark {{ $t('documentos.notice2') }}
        form(
          novalidate=''
          @submit.prevent='onSubmit'
          data-vv-scope='form1')
          .form-row.mb-3
            //- template( v-if='form.fuenteIngresoId === "20076"' )
            .col-12.col-sm-12.col-md-6.mt-0.mt-md-4(v-for='(documento, index) in form.$documentos')
              k-upload(
                :label='documento.NombreDocumento === "DNI Anverso" ? "Cargar la foto de tu DNI Anverso":documento.NombreDocumento === "DNI Reverso"? "Cargar la foto de tu DNI Reverso":documento.NombreDocumento === "Recibo de servicios"? "Cargar un recibo de servicios (02 meses de antigüedad)":documento.NombreDocumento === "Boleta de Pago"? "Cargar una boleta de pago (último mes)": documento.NombreDocumento'
                :info='documento.Vigencia'
                accept='.png,.jpg,.jpeg,.pdf'
                @onFileChange='uploadDocumentos({ file: $event, label: `${documento.TipoDocumento}`, order: `${documento.Numero}` })'
                :isUp='isUp(`${documento.TipoDocumento}`,`${documento.Numero}`)')
          .pt-3
            .row.botonxs
              .col-12.col-sm-12.offset-sm-12.order-2.order-xs-1.text-center
                button.btn.btn-success.btn-md.btn-xs-block.claseboton(
                  v-if='form.documentos.length'
                  :disabled='!isReady'
                  type='submit') {{ $t('components.navigation.submit') }}
                  font-awesome-icon.ml-3(
                    role='button'
                    focusable='false'
                    tabindex='-1'
                    icon='arrow-right')
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import solicitudTypes from '@/store/types/solicitud';
import tipoIngreso from '@/data/tipoIngreso.json';

/* eslint-disable no-nested-ternary */
export default {
  name: 'Documentos',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        stateId: null,
        tipoIngresoId: null,
        fuenteIngresoId: null,
        documentos: [],
        $documentos: [],
        tipoIngresoName: null,
      },
    };
  },
  computed: {
    isReady() {
      let result = false;
      if (this.form.fuenteIngresoId === '20076') {
        result = (this.form.documentos.length) === this.form.$documentos.length && this.form.documentos.every((d) => d.isValid || d.isValid === null);
      } else {
        const cantExcluidos = this.form.$documentos.filter((d) => d.Categoria !== this.form.tipoIngresoName && d.TipoDocumento === 20013).length;
        result = (this.form.documentos.length + cantExcluidos) === this.form.$documentos.length && this.form.documentos.every((d) => d.isValid || d.isValid === null);
      }
      return result;
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    ...mapWaitingActions({
      goToBack: {
        action: solicitudTypes.actions.volver,
        loader: 'global',
      },
    }),
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getDocumentos();
        if (data) {
          const { text } = tipoIngreso.filter((p) => p.id === data.tipoIngresoId).map((d) => ({ value: d.id, text: d.name }))[0];
          data.tipoIngresoName = text;

          this.CommonService.assign(this.form, data);
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async uploadDocumentos(obj) {
      try {
        this.$wait.start('uploading');
        const data = await this.SolicitudService.uploadDocumentos(obj);
        if (data) {
          this.CommonService.assign(this.form, data);
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('uploading');
      }
    },
    async onSubmit() {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        try {
          this.$wait.start('global');
          const result = await this.SolicitudService.putDocumentos({});
          this.$store.commit(solicitudTypes.mutations.setView, result.view);
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      }
    },
    isUp(label, order) {
      const concat = `${label}-${order}`;
      return this.form.documentos.findIndex((d) => (`${d.label}-${d.order}` === concat && (d.isValid || d.isValid === null))) !== -1;
    },
  },
};
</script>
<style>
@media (max-width:620px) {
  .containerxsDoc{
    margin-top: 330px;
    margin-bottom: 80px;
  }
}
</style>
