<!-- <template lang="pug">
  .form-group.position-relative.clasegap
    .clearfix.position-relative
      label.float-left(:for='id' v-text='label')
      span.float-right.mt-1(
        v-if='info'
        ref='info'
        data-toggle='tooltip'
        data-placement='top'
        :title='info')
        font-awesome-icon(
          role='button'
          focusable='false'
          tabindex='-1'
          icon='info-circle')
    input.form-control.form-control-md(
      :id='id'
      :name='name'
      :type='type'
      :maxlength='maxlength'
      :placeholder='placeholder'
      autocomplete='off'
      :value='value'
      :disabled='disabled'
      @input='$emit("input", $event.target.value)'
      @blur='$emit("blur", blur)'
      :class='{ "is-invalid": error, "text-center": textCentered }'
      @change='$emit("change", $event.target.value)'
      @keyup='$emit("keyup", $event.target.value)')
    small.form-text.text-muted {{ helpText }}
    .invalid-tooltip(v-if='error' v-text='error')
</template>

<script>
import $ from 'jquery';

export default {
  name: 'KInput',
  inheritAttrs: false,
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
    validator: 'new',
  },
  props: {
    value: {},
    name: String,
    label: {},
    error: String,
    type: {
      type: String,
      default: 'text',
    },
    maxlength: {
      type: String,
      default: '255',
    },
    placeholder: {
      type: String,
      default: '',
    },
    info: String,
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    textCentered: {
      type: Boolean,
      default: false,
    },
    helpText: String,
    blur: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
    if (this.info) {
      $(this.$refs.info).tooltip();
    }
  },
};
</script>

<style lang="scss" scoped="">
input{
  height: 42px;
}
.clasegap{
  margin-right: 10px;
  margin-left: 10px;
}
</style> -->
<template lang="pug">
div.row(:class=`{infotop:!info, listo:!!info}`)
  div.col.solesTitle.clasegap.soles(v-if="name==='ingresos' || name==='ingresos1' || name==='ingresoAnterior' || name==='ingresos2' || name==='ingresoBrutoMensual1' || name==='ingresoBrutoMensual2' || name==='ingresoBrutoMensual3'") {{ 'S/.(Soles)' }}
  div.col.clasegap.prettyLabel(:class=`{
                soles: name==='ingresos' || name==='ingresos1' || name==='ingresoAnterior' || name==='ingresos2' || name==='ingresoBrutoMensual1' || name==='ingresoBrutoMensual2' || name==='ingresoBrutoMensual3',
                cuotaInit:name==='ingresos' || name==='ingresos1' || name==='ingresoAnterior' || name==='ingresos2' || name==='ingresoBrutoMensual1' || name==='ingresoBrutoMensual2' || name==='ingresoBrutoMensual3',
                siValue: value,
                class1
              }`)
    //- .position-absolute.infoAbsolute
    //-   span.float-right.mt-1.infoRelative(
    //-     v-if='info'
    //-     ref='info'
    //-     data-toggle='tooltip'
    //-     data-placement='top'
    //-     :title='info')
    //-     font-awesome-icon.infoRelative(
    //-       role='button'
    //-       focusable='false'
    //-       tabindex='-1'
    //-       icon='fa-solid fa-info-circle')
    .input-group-append(v-if="textPrepend")
      span.input-group-text {{  textPrepend }}
    input.form-control(
      :id='id'
      :name='name'
      :type='type'
      required
      :maxlength='maxlength'
      autocomplete='off'
      :value='value'
      :disabled='disabled'
      @input='$emit("input", $event.target.value)'
      @blur='$emit("blur", $event.target.value)'
      :class='{ "is-invalid": error, "text-center": textCentered }'
      @change='$emit("change", $event.target.value)'
      @keyup='$emit("keyup", $event.target.value)')
    label.float-left(:for='id' v-text='label' :class=`{info: !!info}`)
    span(v-if='disabled') {{ label}}
    .input-group-append(v-if="textAppend")
      span.input-group-text {{ textAppend }}
    small.form-text.text-muted {{ helpText }}
    .invalid-tooltip(v-if='error' v-text='error')
</template>

<script>
import $ from 'jquery';

export default {
  name: 'KInput',
  inheritAttrs: false,
  $_veeValidate: {
    name() {
      return this.name;
    },
    value() {
      return this.value;
    },
    validator: 'new',
  },
  props: {
    value: {},
    name: String,
    label: {},
    error: String,
    soles: Boolean,
    cuotaInit: Boolean,
    type: {
      type: String,
      default: 'text',
    },
    maxlength: {
      type: String,
      default: '255',
    },
    placeholder: {
      type: String,
      default: '',
    },
    info: String,
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    textCentered: {
      type: Boolean,
      default: false,
    },
    helpText: String,
    textPrepend: {
      type: String,
    },
    textAppend: {
      type: String,
    },
  },
  data() {
    return {
      id: null,
    };
  },
  computed: {
    class1() {
      if (this.textPrepend || this.textAppend) {
        return {
          'input-group': true,
          'mb-3': true,
        };
      }

      return {
        'form-group': true,
        'position-relative': true,
      };
    },
  },
  mounted() {
    this.id = this._uid;
    if (this.info) {
      $(this.$refs.info).tooltip();
    }
  },
};
</script>

<style lang="scss" scoped="">
input{
  height: 54px;
  font-size: 16px;
}
.clasegap{
  // margin-left: 10px;
  width: 100%;
  &.cuotaInit{
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}
.infotop{
  margin-top: 8px;
}
.listo{
  margin-top: 0px;
}

.solesTitle{
  min-width: 90px;
  max-width: 90px;
  padding-right: 0px !important;
  height: 54px;
  text-align: center;
  padding-top: 15px ;
  background: #E9EAE8;
  margin-right: 0px !important;
  margin-left: 25px;
  font-family: 'BebasNeuePro';
  border-radius: 0.25rem;
  font-size: 16px;
  font-weight: bold;
}
.infoRelative{
  position: relative;
  top: -22px;
}
.infoAbsolute{
  right: 15px;
}
.soles{
  margin-left: 15px !important;
  padding-left: 0px !important;
}
.prettyLabel{
  position: relative;
}
.prettyLabel label{
  position: absolute;
  pointer-events: none;
  left: 2;
  top: 0;
  padding: 15px;
  font-size: 17px;
  transition: 0.5s;
  color: #6e6e6e;
  font-family: 'BebasNeuePro-Regular';
  // &.info{
    // position: absolute;
    // pointer-events: none;
    // left: 2;
    // top: 0;
    // margin-top: 25px;
    // padding: 12px;
    // font-size: 1em;
    // transition: 0.5s;
    // color: #6e6e6e;
  // }
}
.prettyLabel input:valid ~ label,
.prettyLabel input:focus ~ label{
  transform: translateX(8px) translateY(-13px) !important;
  font-size: 16px;
  background-color: #fff;
  padding: 0 2px;
  color: #000;
}
.prettyLabel.siValue input:disabled ~ label{
  transform: translateX(8px) translateY(-13px) !important;
  font-size: 16px;
  // background-color: #fff;
  padding: 0px;
  color: #000;
  // background-color: #e9ecef;
  background-color: transparent;
  z-index: 60;
}
.prettyLabel.siValue input:disabled ~ span{
  position: absolute;
  pointer-events: none;
  transform: translateX(6px) translateY(-64px) !important;
  font-size: 16px;
  padding-right: 3px;
  color: transparent;
  font-family: 'BebasNeuePro-Regular';
  margin-top: 10px;
  height: 1px  !important;
  background-color: #fff;
}
.prettyLabel span{
  font-size: 0px;
}

input:valid{
  background-color: #fff;
}
</style>
