<template lang="pug">
  .d-flex.align-items-end.containerxsDictamen2
    .w-100
      .row.imagenFondo
        .col
        .col.modalxsDictamen2.m-3.m-md-4(style="display: flex; justify-content: center;")
          form(
            novalidate=''
            @submit.prevent='onSubmit(null)'
            data-vv-scope='form1')
              .justify-content-center.text-white
                .card.text-center.border-0.bg-transparent(v-for='option in form.evaluacionCredito.options')
                  .bgWhite.m-0.m-md-3.p-0.p-md-4(style="max-width: 600px;border-radius: 4px")
                    div.col
                      div.row
                        div.col-3.p-0.pt-3.m-auto.text-right
                          <img class="img_size2" src="@/assets/images/icon_crdito-preaprobado.svg" alt="" />
                        div.col-9.p-0.pt-3
                          h5.card-title Felicidades
                            | {{' '+ firstName }},
                          h5.card-title2
                            | tienes tu cr&eacute;dito pre-aprobado
                    div.col.m-auto(style="max-width: 430px")
                      div.row.text-right.cuoteDictum.py-2.mt-3
                        div.col-2.p-0.pt-0
                          <img class="img_size3" src="@/assets/icons/icon-check-circle.svg" alt="" />
                        div.col-10.p-0.text-center
                          span.text-plomo.card-title2.m-0 &nbsp;&nbsp; Cuota mensual de: {{ option.ValorCuota | currency(form.monedaId) }}
                    br
                    span.cuota.text-plomo.tceaDictum {{ option.NumeroCuotas - 1 }} meses TCEA {{ option.TCEA | percentage }}
                    br
                    <h5 class="text-plomo infoDictum">Completa tu informacion para que nuestros ejecutivos se pongan en contacto contigo.</h5>
                    div.mb-3
                      button.btn.btn-choose.btn-success.btn-xs-block.clasebotonDictamen.mx-4(
                        @click='onSubmit(option.numeroSolicitud)'
                        type='button') Continuar
                        font-awesome-icon.ml-3(
                          role='button'
                          focusable='false'
                          tabindex='-1'
                          icon='arrow-right')
                    span.mt-4.text-center.k-cursor-pointer.text-primary(@click='showModal = true' style="text-decoration: underline #EB0A1E; font-family: 'BebasNeuePro-Bold' !important") Ver términos y condiciones
                    br
                    button.btn.btn-secondary.btn-xs-block.btn-sm.cartaPreAprobacion2.m-3.p-3(
                      @click='download'
                      type='button')
                      | Descargar carta de Pre-aprobación
                      font-awesome-icon.ml-3(
                        ref='info'
                        role='button'
                        focusable='false'
                        tabindex='-1'
                        icon='download')
      k-modal(
        v-if='showModal'
        @close='showModal = false')
        p {{ modalText }}
</template>

<script>
import { mapGetters } from 'vuex';
import solicitudTypes from '@/store/types/solicitud';
import moment from 'moment-timezone';

export default {
  name: 'Dictamen2',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        modeloDescripcion: null,
        montoVehiculoDolares: null,
        montoVehiculoSoles: null,
        monedaId: null,
        evaluacionCredito: {
          options: [],
        },
        toyotaValueDolares: null,
        toyotaValueSoles: null,
      },
      showModal: false,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters({
      firstName: solicitudTypes.getters.getFirstName,
      token: solicitudTypes.getters.getToken,
    }),
    modalText() {
      const {
        MonedaCredito,
        PorcentajeBalon,
        NumeroCuotas,
        ValorCuota,
        TipoCambio,
        Porcentaje,
        TEA,
        TCEA,
        BonoDolares,
        MontoSeguro,
      } = this.form.evaluacionCredito.options[0];

      let bonoUSD = 0;
      let bonoPEN = 0;
      let valCuotaUSD = 0;
      let valCuotaPEN = 0;
      let MontoSeguroUSD = 0;
      let MontoSeguroPEN = 0;
      let bonoTexto = '';
      // let MontoGpsUSD = 0;
      // let MontoGpsPEN = 0;

      if (MonedaCredito === 'DOLARES') {
        bonoUSD = BonoDolares;
        bonoPEN = (BonoDolares * TipoCambio).toFixed(2);
        valCuotaUSD = ValorCuota;
        valCuotaPEN = (ValorCuota * TipoCambio).toFixed(2);
        MontoSeguroUSD = MontoSeguro;
        MontoSeguroPEN = (MontoSeguro * TipoCambio).toFixed(2);
        // MontoGpsUSD = 0;
        // MontoGpsPEN = 0;
      } else {
        bonoUSD = BonoDolares;
        bonoPEN = (BonoDolares * TipoCambio).toFixed(2);
        valCuotaUSD = (ValorCuota / TipoCambio).toFixed(2);
        valCuotaPEN = ValorCuota;
        MontoSeguroUSD = (MontoSeguro / TipoCambio).toFixed(2);
        MontoSeguroPEN = MontoSeguro;
        // MontoGpsUSD = 0;
        // MontoGpsPEN = 0;
      }
      // const montoSolicitado = monedaId === 'USD' ? montoVehiculoDolares : montoVehiculoSoles
      const cuoInicialDolares = (((this.form.montoVehiculoDolares - bonoUSD) * Porcentaje) / 100).toFixed(2);
      const cuoInicialSoles = (((this.form.montoVehiculoSoles - bonoPEN) * Porcentaje) / 100).toFixed(2);
      const cuoFinalDolares = (((this.form.montoVehiculoDolares - bonoUSD) * PorcentajeBalon) / 100).toFixed(2);
      const cuoFinalSoles = (((this.form.montoVehiculoSoles - bonoPEN) * PorcentajeBalon) / 100).toFixed(2);

      if (bonoUSD > 0) {
        bonoTexto = `, el precio referencial del
              vehículo incluye el bono de descuento que se otorga por la compra del vehículo de hasta
              $ ${bonoUSD} o S/ ${bonoPEN}. El cliente aplicar\u00E1 el bono solo al precio del
                vehículo`;
      }

      const hastv = this.form.toyotaValueSoles && this.form.toyotaValueSoles > 0;

      const strtv = `El Paquete Toyota Value, lo otorga MAF Perú, previa aprobación del Plan Toyota Life según política crediticia de MAF,
              incluye los mantenimientos prepagados hasta los 20,000km alineados a su libreta de garantía. Asimismo, cabe resaltar
              que la disponibilidad de este paquete, beneficios adicionales, así como los términos y condiciones aplicables pueden
              variar según el Concesionario, modelo y versión. Consulte con su concesionario de preferencia respecto a la
              disponibilidad y beneficios del paquete Toyota Value para cada modelo.`;

      return `"El plan Toyota Life lo otorga MAF previa evaluaci\u00F3n y aprobaci\u00F3n crediticia para el modelo
              ${this.form.modeloDescripcion}. S\u00F3lo uso particular del vehículo. Año de fabricaci\u00F3n ${moment().year()}. La Pre
              Aprobaci\u00F3n y las Condiciones Referenciales del Cr\u00E9dito tienen vigencia durante 15 días
              calendario contados desde ${moment().format('DD/MM/YYYY')} hasta ${moment().add(15, 'days').format('DD/MM/YYYY')}
              de ${moment().year()}. Precio referencial del ${this.form.modeloDescripcion} S/
              ${this.form.montoVehiculoSoles} o $ ${this.form.montoVehiculoDolares}${bonoTexto}, cuota inicial ${Porcentaje}%
              equivalente a $ ${cuoInicialDolares} o S/ ${cuoInicialSoles}, ${NumeroCuotas - 1} cuotas mensuales
              de $ ${valCuotaUSD} o S/ ${valCuotaPEN}, y una cuota final N° ${NumeroCuotas} de ${PorcentajeBalon}% del valor del vehículo
              por un monto de $ ${cuoFinalDolares} o S/ ${cuoFinalSoles}, incluye Seguro Toyota; monto a financiar
              $ ${MontoSeguroUSD} o S/ ${MontoSeguroPEN}, incluye el costo del ${hastv ? 'Toyota Value, GPS' : 'GPS'}, seguro de
              desgravamen, gastos notariales y registrales. TEA referencial: ${TEA}%, TCEA referencial: de ${TCEA}%.
              Tipo de cambio referencial S/ ${TipoCambio}, al momento de la transacci\u00F3n se aplicar\u00E1 el tipo de cambio
              vigente del día. Para mayor informaci\u00F3n de condiciones y requisitos del plan Toyota Life y condiciones de
              acceso a bono otorgado por MAF, tasas de inter\u00E9s, comisiones y gastos estar\u00E1n disponibles en
              el tarifario en oficinas de atenci\u00F3n y en www.mafperu.com. Informaci\u00F3n difundida de acuerdo a
              ley N° 28587 y Res SBS N° 3274-2017. No acumulable con otras promociones. Aplica a nivel
              nacional.
              Las características y condiciones antes descritas son únicamente referenciales, se encuentran
              sujetas a la entrega posterior de la documentaci\u00F3n declarada y/o adicional a MAF; y, a la previa
              evaluaci\u00F3n crediticia y aprobaci\u00F3n finales de MAF. Para tales efectos, agradeceremos descargue
              la carta de pre aprobaci\u00F3n y se acerque a cualquiera de nuestras oficinas especiales, en los
              distintos puntos a nivel nacional.
              ${hastv ? strtv : ''}"`;
    },
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getDictamen();
        if (data) {
          this.CommonService.assign(this.form, data);
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async onSubmit(numeroSolicitud) {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        try {
          this.$wait.start('global');
          const result = await this.SolicitudService.putDictamen({ numeroSolicitud });
          this.$store.commit(solicitudTypes.mutations.setView, result.view);
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      }
    },
    download() {
      window.open(`${process.env.VUE_APP_API_URL}/printPdf?auth=${this.token}`, '_blank');
    },
  },
};
</script>

<style scoped="">
  .btn-choose {
    min-width: 13rem;
    padding: 0.5rem;
    font-size: 16px;
    font-family: 'BebasNeuePro-Bold';
  }
  h1.congratz {
    font-size: 3.5rem;
  }
  h1.cuota {
    font-size: 3rem;
  }
  small.cuota {
    font-size: 1rem;
  }
  .containerxsDictamen2{
    margin-top: 10px !important;
    margin-bottom: 80px;
    background-color: #EB0A1E;
  }
  .bgWhite{
    background-color: #fff;
  }

@media only screen and (max-width:620px) {
    h1.congratz {
      font-size: 2.2rem;
    }
  }
.imagenFondo{
  background-image: url('../assets/bg/img_creditopreaprobado.png');
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 50vw;
}
.card-title{
  font-size: 30px !important;
  color: #EB0A1E;
  font-family: 'BebasNeuePro-Bold';
  margin-bottom: 0px;
}
.card-title2{
  font-size: 30px !important;
  color: #4A4A4A;
  font-family: 'BebasNeuePro-Bold';
}
.img_size2{
  /* max-height: 100%; */
  width: 100%;
  max-width: 85px;
}
.img_size3{
  /* max-height: 100%; */
  width: 38px;
  max-width: 60px;
}
.cuoteDictum{
  border-radius: 3px 25px 3px 3px;
  background-color: #E9EAE8;
  margin: auto;
}
.infoDictum{
  font-size: 16px;
  font-family: 'BebasNeuePro-Bold';
  color: #616161 !important;
}
.tceaDictum{
  margin-top: 0px;
  font-size: 14px;
  font-family: 'BebasNeuePro';
  color: #616161 !important;
}
.cartaPreAprobacion2{
  border-radius: 3px 25px 3px 3px;
  background-color: #E9EAE8;
  font-size: 16px;
  padding: 10px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 300px;
  border: solid #4A4A4A 1px;
  font-family: 'BebasNeuePro-Bold';
  color:#4A4A4A;
}
.clasebotonDictamen{
  border-radius: 3px 25px 3px 3px;
  height: 50px;
  width: auto;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media only screen and (max-width:620px) {
    .containerxsDictamen2{
      margin-top: 10px !important;
      margin-bottom: 80px;
      background-color: #FFF;
    }
  .imagenFondo{
    background-image: url('../assets/bg/img_creditopreaprobado.png');
    background-size: contain;
    background-position: left top 50px !important;
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: 103vw;
    height: 500px;
  }
  .bgwhite{
    background-color: transparent;
    margin: 4px;
  }
  .modalxsDictamen2{
    margin: 0px !important;
    position: fixed;
    bottom: 0vh !important;
    left: 0;
    padding: 0;
    width: 100vw !important;
    border-radius: 50px 0px 0px 0px;
    background-color: #fff;
    z-index: 100;
  }
  .bgWhite{
    background-color: transparent;
  }
}
</style>
