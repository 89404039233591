<template lang="pug">
  .container.mt-4.containerxsPredoc
    .row.justify-content-center
      .col-12.col-sm-12.col-md-12.text-center
        form(
          novalidate=''
          @submit.prevent='onSubmit'
          data-vv-scope='form1')
          k-button-group(
            :label='$t("preDocumentos.form1.adjuntarDocumentos.label")'
            :error='errors.first("form1.adjuntarDocumentos")')
            k-button-radio(
              :label='$t("preDocumentos.form1.adjuntarDocumentos.options[0].label")'
              :data-vv-as='$t("preDocumentos.form1.adjuntarDocumentos.label")'
              name='adjuntarDocumentos'
              class="documentosTiendaAdjuntar"
              :value='1'
              v-validate='"required"'
              :error='errors.first("form1.adjuntarDocumentos")'
              v-model.number='form.adjuntarDocumentos')
            k-button-radio(
              :label='$t("preDocumentos.form1.adjuntarDocumentos.options[1].label")'
              name='adjuntarDocumentos'
              class="documentosTiendaAdjuntar"
              :value='0'
              :error='errors.first("form1.adjuntarDocumentos")'
              v-model.number='form.adjuntarDocumentos')
          template(v-if='form.adjuntarDocumentos === 0')
            span.text-center(v-if='form.fuenteIngresoId === "NNNNN"' style="font-family: 'BebasNeuePro-Regular'") {{ $t('preDocumentos.form1.adjuntarDocumentos.options[1].info1') }}
            span.text-center(v-else='' style="font-family: 'BebasNeuePro-Regular'") {{ $t('preDocumentos.form1.adjuntarDocumentos.options[1].info2') }}
          .pt-3
            .row.botonxs
              .col-12.col-sm-12.order-2.order-xs-1.text-center
                button.btn.btn-success.btn-lg.btn-xs-block.claseboton.px-5(
                  v-if='!!form.adjuntarDocumentos'
                  :disabled='!isReady'
                  type='submit') {{ $t('preDocumentos.navigation.submit1') }}
                button.btn.btn-success.btn-lg.btn-xs-block.claseboton.px-5(
                  v-else=''
                  :disabled='!isReady'
                  type='submit') {{ $t('preDocumentos.navigation.submit2') }}
</template>

<script>
import solicitudTypes from '@/store/types/solicitud';

export default {
  name: 'Documentos',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        fuenteIngresoId: null,
        adjuntarDocumentos: null,
      },
    };
  },
  computed: {
    isReady() {
      return this.form.adjuntarDocumentos !== null;
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getPreDocumentos();
        if (data) {
          data.adjuntarDocumentos = +data.adjuntarDocumentos;
          this.CommonService.assign(this.form, data);
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async onSubmit() {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        try {
          this.$wait.start('global');
          const result = await this.SolicitudService.putPreDocumentos({ adjuntarDocumentos: !!this.form.adjuntarDocumentos });
          this.$store.commit(solicitudTypes.mutations.setView, result.view);
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      }
    },
  },
};
</script>
<style>
.documentosTiendaAdjuntar{
  margin-left: 0px;
  max-width: 150px !important;
  min-width: 150px !important;
}

@media (max-width:620px) {
  .containerxsPredoc{
    margin-top: 300px !important;
    margin-bottom: 80px;
  }
}
</style>
