<template lang="pug">
  .bg-primary.containerxsProforma
    .row.imagenFondoEnd.w-100.bgWhite.p-0.m-0
      .col.imagenFondoProforma.p-0.m-0
      .col-12.col-md-6.offset-md-3.p-0.p-md-4.mt-3.mb-3.modalxsProforma(style="display: flex; justify-content: center;")
        form(
          novalidate=''
          @submit.prevent='onSubmit'
          data-vv-scope='form1')
          .row.p-3.p-md-4
            .col.text-center.m-3.bg-white.px-3(style="max-width: 500px;border-radius: 4px")
              h2.card-title3.mt-5
                | Felicidades {{ firstName }},
              h2.card-title4 Estas muy cerca de finalizar tu solicitud de crédito vehicular
              a(href='javascript:void(0)' @click='download')
                img.download.mt-3(src='@/assets/icons/Group 194_gray.png')
              //- p.h4.text-dark.font-weight-bold {{ $t('proforma.hello') }} {{ firstName }},
              br
              br
              span.pt-4.mb-0(style="font-family: 'BebasNeuePro-Bold' !important")
                | Descarga presionando aqu&iacute; la carta de Pre-aprobaci&oacute;n y pres&eacute;ntala el d&iacute;a de cita en el concesionario que elijas.
              span(style="font-family: 'BebasNeuePro-Bold' !important")  Te enviaremos una copia de la carta de Pre-aprobaci&oacute;n a tu correo electr&oacute;nico
              br
              br
              .col-12.col-sm-12.order-2.order-xs-1.text-center.mb-5
                button.btn.btn-success.btn-md.btn-xs-block.font-weight-bold.claseboton
                  | {{ $t("components.navigation.submit") }}
                  font-awesome-icon.ml-3(
                    role='button'
                    focusable='false'
                    tabindex='-1'
                    icon='arrow-right')
</template>

<script>
import { mapGetters } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import solicitudTypes from '@/store/types/solicitud';

export default {
  name: 'Proforma',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        modelo: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      firstName: solicitudTypes.getters.getFirstName,
      token: solicitudTypes.getters.getToken,
    }),
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    ...mapWaitingActions({
      goToBack: {
        action: solicitudTypes.actions.volver,
        loader: 'global',
      },
    }),
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getProforma();
        if (data) {
          this.CommonService.assign(this.form, data);
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async onSubmit() {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        try {
          this.$wait.start('global');
          const data = {};
          const result = await this.SolicitudService.putProforma(data);
          this.$store.commit(solicitudTypes.mutations.setView, result.view);
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      }
    },
    download() {
      window.open(`${process.env.VUE_APP_API_URL}/printPdf?auth=${this.token}`, '_blank');
    },
  },
};
</script>

<style lang="scss">
img.download {
}
h2 {
  font-size: 1.7rem
}
@media only screen and (max-width:620px) {
  h2 {
    font-size: 1.4rem;
  }
  .img_size {
    width: 50%;
  }
}
.imagenFondoEnd{
  background-image: url('../assets/images/img_feliciddades_daniel.png');
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  background-size: 50vw;
  height: 100%;
}
.card-title3{
  font-size: 34px;
  color: #EB0A1E;
  font-family: 'BebasNeuePro-Bold';
  margin-bottom: 0px;
}
.card-title4{
  font-size: 34px;
  color: #4A4A4A;
  font-family: 'BebasNeuePro-Bold';
}
@media (max-width:767px) {
  .imagenFondoEnd{
    background-image: none;
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    background-size: 50vw;
    height: 100%;
  }
}
@media (max-width:620px) {
  .imagenFondoProforma{
    background-image: url('../assets/images/img_feliciddades_daniel.png');
    background-size: contain;
    background-position: left top !important;
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: 103vw;
    height: 500px;
  }
  .containerxsProforma{
    margin-top: 76px !important;
    margin-bottom: 80px;
  }
  .modalxsProforma{
    position: fixed;
    top: 40vh !important;
    left: 0;
    padding: 0;
    width: 100% !important;
    border-radius:50px 0px 0px 0px;
    background-color: #fff;
    z-index: 100;
  }
}
</style>
