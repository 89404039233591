import injector from 'vue-inject';
import alertType from '@/store/types/alert';
import solicitudTypes from '@/store/types/solicitud';

const state = {
  status: '',
  dni: null,
  estadoCivilId: null,
  departamentoId: null,
  provinciaId: null,
  distritoId: null,
  direccion: null,
  usoDeInformacionPersonal: null,
  view: 'ValidacionInicial',
  token: null,
  primerNombre: null,
  segundoNombre: null,
  primerApellido: null,
  segundoApellido: null,
  logged: false,
};

const getters = {
  [solicitudTypes.getters.getView](state) {
    return state.view;
  },
  [solicitudTypes.getters.getToken](state) {
    return state.token;
  },
  [solicitudTypes.getters.getFullname](state) {
    return `${state.primerNombre} ${state.primerApellido}`;
  },
  [solicitudTypes.getters.getFirstName](state) {
    if (typeof state.primerNombre !== 'string') return '';
    let fn = `${state.primerNombre.split(' ')}`;
    fn = fn.substring(0, fn.indexOf(',') < 0 ? fn.length : fn.indexOf(','));
    fn = fn.charAt(0) + fn.toLowerCase().slice(1);
    return fn;
  },
};

const mutations = {
  [solicitudTypes.mutations.cargaInicialRequest](state) {
    state.status = '⏳';
    state.dni = null;
    state.estadoCivilId = null;
    state.departamentoId = null;
    state.provinciaId = null;
    state.distritoId = null;
    state.direccion = null;
    state.usoDeInformacionPersonal = null;
    state.view = 'ValidacionInicial';
    state.token = null;
    state.primerNombre = null;
    state.segundoNombre = null;
    state.primerApellido = null;
    state.segundoApellido = null;
    state.logged = false;
  },
  [solicitudTypes.mutations.cargaInicialSuccess](state, {
    dni,
    estadoCivilId,
    departamentoId,
    provinciaId,
    distritoId,
    direccion,
    usoDeInformacionPersonal,
    view,
    token,
    primerNombre,
    segundoNombre,
    primerApellido,
    segundoApellido,
  }) {
    state.status = '✅';
    state.dni = dni;
    state.estadoCivilId = estadoCivilId;
    state.departamentoId = departamentoId;
    state.provinciaId = provinciaId;
    state.distritoId = distritoId;
    state.direccion = direccion;
    state.usoDeInformacionPersonal = usoDeInformacionPersonal;
    state.view = view;
    state.token = token;
    state.primerNombre = primerNombre;
    state.segundoNombre = segundoNombre;
    state.primerApellido = primerApellido;
    state.segundoApellido = segundoApellido;
    state.logged = true;
  },
  [solicitudTypes.mutations.cargaInicialFailure](state) {
    state.status = '❌';
    state.dni = null;
    state.estadoCivilId = null;
    state.departamentoId = null;
    state.provinciaId = null;
    state.distritoId = null;
    state.direccion = null;
    state.usoDeInformacionPersonal = null;
    state.view = 'ValidacionInicial';
    state.token = null;
    state.primerNombre = null;
    state.segundoNombre = null;
    state.primerApellido = null;
    state.segundoApellido = null;
    state.logged = false;
  },
  [solicitudTypes.mutations.validacionInicialRequest](state) {
    state.status = '⏳';
    state.dni = null;
    state.estadoCivilId = null;
    state.departamentoId = null;
    state.provinciaId = null;
    state.distritoId = null;
    state.direccion = null;
    state.usoDeInformacionPersonal = null;
    state.view = 'ValidacionInicial';
    state.token = null;
    state.primerNombre = null;
    state.segundoNombre = null;
    state.primerApellido = null;
    state.segundoApellido = null;
    state.logged = false;
  },
  [solicitudTypes.mutations.validacionInicialSuccess](state, {
    dni,
    estadoCivilId,
    departamentoId,
    provinciaId,
    distritoId,
    direccion,
    usoDeInformacionPersonal,
    view,
    token,
    primerNombre,
    segundoNombre,
    primerApellido,
    segundoApellido,
  }) {
    state.status = '✅';
    state.dni = dni;
    state.estadoCivilId = estadoCivilId;
    state.departamentoId = departamentoId;
    state.provinciaId = provinciaId;
    state.distritoId = distritoId;
    state.direccion = direccion;
    state.usoDeInformacionPersonal = usoDeInformacionPersonal;
    state.view = view;
    state.token = token;
    state.primerNombre = primerNombre;
    state.segundoNombre = segundoNombre;
    state.primerApellido = primerApellido;
    state.segundoApellido = segundoApellido;
    state.logged = true;
  },
  [solicitudTypes.mutations.validacionInicialFailure](state) {
    state.status = '❌';
    state.dni = null;
    state.estadoCivilId = null;
    state.departamentoId = null;
    state.provinciaId = null;
    state.distritoId = null;
    state.direccion = null;
    state.usoDeInformacionPersonal = null;
    state.view = 'ValidacionInicial';
    state.token = null;
    state.primerNombre = null;
    state.segundoNombre = null;
    state.primerApellido = null;
    state.segundoApellido = null;
    state.logged = false;
  },
  [solicitudTypes.mutations.logout](state) {
    state.status = '✅';
    state.dni = null;
    state.estadoCivilId = null;
    state.departamentoId = null;
    state.provinciaId = null;
    state.distritoId = null;
    state.direccion = null;
    state.usoDeInformacionPersonal = null;
    state.view = 'ValidacionInicial';
    state.token = null;
    state.primerNombre = null;
    state.segundoNombre = null;
    state.primerApellido = null;
    state.segundoApellido = null;
    state.logged = false;
  },
  [solicitudTypes.mutations.setView](state, view) {
    state.status = '✅';
    state.view = view;
  },
  [solicitudTypes.mutations.volverRequest]() {
    state.status = '⏳';
  },
  [solicitudTypes.mutations.volverSuccess](state, view) {
    state.status = '✅';
    state.view = view;
  },
  [solicitudTypes.mutations.volverFailure]() {
    state.status = '❌';
  },
};

const actions = {
  [solicitudTypes.actions.dniChecker]: injector.encase(['SolicitudService'], (SolicitudService) => async ({ dispatch, commit }, request) => {
    try {
      await dispatch(alertType.actions.clear);
      commit(solicitudTypes.mutations.cargaInicialRequest);
      const response = await SolicitudService.dniChecker(request);
      if (!response) {
        // dispatch(alertType.actions.warning, 'No se encontró DNI');
        // this.$swal({
        //   type: 'success',
        //   title: 'Oops...',
        //   text: 'Archivo no va1lido',
        // });
      }
      return response;
    } catch (error) {
      commit(solicitudTypes.mutations.cargaInicialFailure);
      dispatch(alertType.actions.error, error);
      return null;
    }
  }),
  [solicitudTypes.actions.cargaInicial]: injector.encase(['SolicitudService'], (SolicitudService) => async ({ dispatch, commit }, request) => {
    try {
      await dispatch(alertType.actions.clear);
      commit(solicitudTypes.mutations.cargaInicialRequest);
      const response = await SolicitudService.cargaInicial(request);
      commit(solicitudTypes.mutations.cargaInicialSuccess, response);
      return response;
    } catch (error) {
      commit(solicitudTypes.mutations.cargaInicialFailure);
      dispatch(alertType.actions.error, error);
      return null;
    }
  }),
  [solicitudTypes.actions.validacionInicial]: injector.encase(['SolicitudService'], (SolicitudService) => async ({ dispatch, commit, state }, request) => {
    try {
      await dispatch(alertType.actions.clear);
      commit(solicitudTypes.mutations.validacionInicialRequest);
      const response = await SolicitudService.validacionInicial(request);
      if (response === true) {
        return true;
      }
      commit(solicitudTypes.mutations.validacionInicialSuccess, response);
      return state.view;
    } catch (error) {
      commit(solicitudTypes.mutations.validacionInicialFailure);
      dispatch(alertType.actions.error, error);
      return null;
    }
  }),
  [solicitudTypes.actions.logout]: injector.encase(['SolicitudService'], (SolicitudService) => async ({ dispatch, commit }) => {
    await dispatch(alertType.actions.clear);
    await SolicitudService.logout();
    commit(solicitudTypes.mutations.logout);
  }),
  [solicitudTypes.actions.volver]: injector.encase(['SolicitudService'], (SolicitudService) => async ({ dispatch, commit, state }) => {
    try {
      await dispatch(alertType.actions.clear);
      commit(solicitudTypes.mutations.volverRequest);
      const view = await SolicitudService.volver({ view: state.view });
      commit(solicitudTypes.mutations.volverSuccess, view);
      if (view === 'ValidacionInicial') {
        commit(solicitudTypes.mutations.logout);
      }
      return view;
    } catch (error) {
      commit(solicitudTypes.mutations.volverFailure);
      dispatch(alertType.actions.error, error);
      return null;
    }
  }),
};

export default {
  state,
  getters,
  mutations,
  actions,
};
