import { render, staticRenderFns } from "./Dictamen2.vue?vue&type=template&id=3042ec7b&scoped=true&lang=pug&"
import script from "./Dictamen2.vue?vue&type=script&lang=js&"
export * from "./Dictamen2.vue?vue&type=script&lang=js&"
import style0 from "./Dictamen2.vue?vue&type=style&index=0&id=3042ec7b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3042ec7b",
  null
  
)

export default component.exports