<template lang="pug">
  .form-group.position-relative
    label(v-text='label')
    slot
    .invalid-tooltip(v-if='error' v-text='error')
</template>

<script>
export default {
  name: 'KRadioGroup',
  inheritAttrs: false,
  props: {
    label: String,
    error: String,
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
  },
};
</script>
