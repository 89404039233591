import Vue from 'vue';
// import axios from 'axios';
import awsEndpoint from '@/common/awsEndpoint';
import ServerError from '@/common/ServerError';
// import { retry } from './retry';

// const lambdaApi = axios.create({
//   baseURL: `${process.env.VUE_APP_LAMBDA}`,
// });

function getFilePathExtension(path) {
  const filename = path.split('\\').pop().split('/').pop();
  return filename.substr((Math.max(0, filename.lastIndexOf('.')) || Infinity) + 1);
}

class SolicitudService {
  constructor(axios, apiUrl) {
    this.axios = axios;
    this.apiUrl = apiUrl;
  }

  async dniChecker(params) {
    Vue.$log.debug('dniChecker', params);
    try {
      const { data } = await this.axios.post('checkNumDoc', params);
      Vue.$log.debug('dniChecker', data);
      return data;
    } catch (error) {
      this.axios.defaults.headers.common['Authorization'] = null;
      throw new ServerError(error);
    }
  }

  async cargaInicial(params) {
    Vue.$log.debug('cargaInicial', params);
    try {
      const { data: { data } } = await this.axios.get('validacionInicial', { params });
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
      Vue.$log.debug('cargaInicial', data);
      return data;
    } catch (error) {
      this.axios.defaults.headers.common['Authorization'] = null;
      throw new ServerError(error);
    }
  }

  async contactenos(params) {
    Vue.$log.debug('contactenos', params);
    try {
      const { data: { data } } = await this.axios.post('contactenos', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async validacionInicial(params) {
    Vue.$log.debug('validacionInicial', params);
    try {
      const { data: { data } } = await this.axios.post('validacionInicial', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  logout() {
    this.axios.defaults.headers.common['Authorization'] = null;
  }

  async getRucInfo(params) {
    Vue.$log.debug('getRucInfo', params);
    try {
      const { data: { data } } = await this.axios.post('getRucInfo', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async volver(params) {
    Vue.$log.debug('volver', params);
    try {
      const { data: { data } } = await this.axios.post('volver', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getDatosPersonales() {
    try {
      const { data: { data } } = await this.axios.get('datosPersonales');
      Vue.$log.debug('getDatosPersonales', data);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async putDatosPersonales(params) {
    Vue.$log.debug('putDatosPersonales', params);
    try {
      const { data: { data } } = await this.axios.post('datosPersonales', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getDireccion1() {
    try {
      const { data: { data } } = await this.axios.get('direccion1');
      Vue.$log.debug('getDireccion1', data);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async putDireccion1(params) {
    Vue.$log.debug('putDireccion1', params);
    try {
      const { data: { data } } = await this.axios.post('direccion1', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getInformacionCotizacion() {
    try {
      const { data: { data } } = await this.axios.get('informacionCotizacion');
      Vue.$log.debug('getInformacionCotizacion', data);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async putInformacionCotizacion(params) {
    Vue.$log.debug('putInformacionCotizacion', params);
    try {
      const { data: { data } } = await this.axios.post('informacionCotizacion', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getUnEjecutivoContactara() {
    try {
      const { data: { data } } = await this.axios.get('UnEjecutivoContactara');
      Vue.$log.debug('UnEjecutivoContactara', data);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async putUnEjecutivoContactara(params) {
    Vue.$log.debug('putUnEjecutivoContactara', params);
    try {
      const { data: { data } } = await this.axios.post('UnEjecutivoContactara', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getVisualizacionModelo() {
    try {
      const { data: { data } } = await this.axios.get('visualizacionModelo');
      Vue.$log.debug('getVisualizacionModelo', data);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async putVisualizacionModelo(params) {
    Vue.$log.debug('putVisualizacionModelo', params);
    try {
      const { data: { data } } = await this.axios.post('visualizacionModelo', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getDatosLaborales() {
    try {
      const { data: { data } } = await this.axios.get('datosLaborales');
      Vue.$log.debug('getDatosLaborales', data);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async putDatosLaborales(params) {
    Vue.$log.debug('putDatosLaborales', params);
    try {
      const { data: { data } } = await this.axios.post('datosLaborales', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getVisitaConcesionario() {
    try {
      const { data: { data } } = await this.axios.get('visitaConcesionario');
      Vue.$log.debug('getVisitaConcesionario', data);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async putVisitaConcesionario(params) {
    Vue.$log.debug('putVisitaConcesionario', params);
    try {
      const { data: { data } } = await this.axios.post('visitaConcesionario', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getIngresos() {
    try {
      const { data: { data } } = await this.axios.get('ingresos');
      Vue.$log.debug('getIngresos', data);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async putIngresos(params) {
    Vue.$log.debug('putIngresos', params);
    try {
      const { data: { data } } = await this.axios.post('ingresos', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getDictamen() {
    try {
      const { data: { data } } = await this.axios.get('dictamen');
      Vue.$log.debug('getDictamen', data);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async putDictamen(params) {
    Vue.$log.debug('putDictamen', params);
    try {
      const { data: { data } } = await this.axios.post('dictamen', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getProforma() {
    try {
      const { data: { data } } = await this.axios.get('Proforma');
      Vue.$log.debug('getProforma', data);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async putProforma(params) {
    Vue.$log.debug('putProforma', params);
    try {
      const { data: { data } } = await this.axios.post('Proforma', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getPin() {
    try {
      const { data: { data } } = await this.axios.get('pin');
      Vue.$log.debug('getPin', data);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async putPin(params) {
    Vue.$log.debug('putPin', params);
    try {
      const { data: { data } } = await this.axios.post('pin', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async requestPin() {
    Vue.$log.debug('requestPin');
    try {
      const { data: { data } } = await this.axios.post('requestPin');
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getPreDocumentos() {
    try {
      const { data: { data } } = await this.axios.get('preDocumentos');
      Vue.$log.debug('getPreDocumentos', data);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async putPreDocumentos(params) {
    Vue.$log.debug('putPreDocumentos', params);
    try {
      const { data: { data } } = await this.axios.post('preDocumentos', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getDocumentos() {
    try {
      const { data: { data } } = await this.axios.get('documentos');
      Vue.$log.debug('getDocumentos', data);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async putDocumentos(params) {
    Vue.$log.debug('putDocumentos', params);
    try {
      const { data: { data } } = await this.axios.post('documentos', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async uploadDocumentos(params) {
    Vue.$log.debug('uploadDocumentos', params);
    const { file, label, order } = params;
    const fileExtension = getFilePathExtension(file.name).toUpperCase();
    let response;
    if (fileExtension === 'PDF') {
      response = await awsEndpoint.sendFile(file, {
        signingURL: `${this.apiUrl}/documentos/signedUrl?name=${file.name}&type=${file.type}`,
        headers: {
          Authorization: this.axios.defaults.headers.common['Authorization'],
        },
      });
    } else {
      const formData = new FormData();
      formData.append('file', file);
      const { data: { data } } = await this.axios.post('documentos/uploadImage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      response = data;
    }

    // try {
    //   const { data } = await retry(() => lambdaApi.post('s3-utils/getObjectInfo', {
    //     Bucket: response.Bucket,
    //     Key: response.Key,
    //   }), 5, 2000);

    //   if (!data.clean) {
    //     throw new ServerError(new Error('Hemos detectado que el archivo que estas adjuntando no es seguro, intenta con otro archivo.'));
    //   }
    // } catch (err) {
    //   throw new ServerError(new Error('Hubo un error al procesar el archivo, vuelve a intentarlo.'));
    // }

    try {
      const {
        Bucket: bucket, ETag: etag, Key: key, Location: location,
      } = response;
      const { data: { data } } = await this.axios.post('documentos/upload', {
        bucket,
        etag,
        key,
        location,
        label,
        filename: file.name,
        filetype: file.type,
        order,
      });
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async getFin() {
    try {
      const { data: { data } } = await this.axios.get('fin');
      Vue.$log.debug('getFin', data);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }

  async putFin(params) {
    Vue.$log.debug('putFin', params);
    try {
      const { data: { data } } = await this.axios.post('fin', params);
      return data;
    } catch (error) {
      throw new ServerError(error);
    }
  }
}

export default SolicitudService;
