import { createHelpers } from 'vuex-map-fields';
import globalTypes from '@/store/types/global';

const { getGlobalField, updateGlobalField } = createHelpers({
  getterType: 'getGlobalField',
  mutationType: 'updateGlobalField',
});

const state = {
  environment: process.env.NODE_ENV,
};

const getters = {
  [globalTypes.getters.isProduction](state) {
    return state.environment === 'production';
  },
  getGlobalField,
};

const mutations = {
  updateGlobalField,
};

const actions = {
};

export default {
  state,
  getters,
  mutations,
  actions,
};
