<template lang="pug">
  .container.containerxsPerso
    .row
      .col-12.col-md-8.offset-md-2
        //- h5.mt-3.mb-5.text-center.text-dark.font-italic {{ $t('datosPersonales.title') }}
        form(
          novalidate=''
          @submit.prevent='onSubmit'
          data-vv-scope='form1')
          .row.mt-4
            .col-12.col-sm-12.col-md-6.mt-2
              k-input(
                :info='$t("datosPersonales.form1.numeroTelefonoMovil.info")'
                :label='$t("datosPersonales.form1.numeroTelefonoMovil.label")'
                :data-vv-as='$t("datosPersonales.form1.numeroTelefonoMovil.label")'
                name='numeroTelefonoMovil'
                maxlength='9'
                v-validate='"required|numeric|min:9|max:9"'
                :error='errors.first("form1.numeroTelefonoMovil")'
                v-model='form.numeroTelefonoMovil')
            .col-12.col-sm-12.col-md-6.mt-4.mt-md-0
              k-select(
                :label='$t("datosPersonales.form1.operadorTelefoniaMovilId.label")'
                :data-vv-as='$t("datosPersonales.form1.operadorTelefoniaMovilId.label")'
                name='operadorTelefoniaMovilId'
                v-validate='"required"'
                :error='errors.first("form1.operadorTelefoniaMovilId")'
                v-model.number='form.operadorTelefoniaMovilId'
                :options='operadoresTelefoniaMovil'
                @change='form.operadorTelefoniaMovilId = $event')
          .row.mt-2
            .col-12.col-sm-12(:class='{ "col-md-6": form.hasConyuge || form.hasConviviente }')
              k-input(
                :label='$t("datosPersonales.form1.correoElectronico.label")'
                :data-vv-as='$t("datosPersonales.form1.correoElectronico.label")'
                name='correoElectronico'
                maxlength='70'
                v-validate='"required|email|min:10|max:70"'
                :error='errors.first("form1.correoElectronico")'
                v-model='form.correoElectronico')
            .col-12.col-sm-12.col-md-6(v-if='form.hasConyuge || form.hasConviviente')
              k-input(
                :label='labelConyugeOrConviviente'
                :data-vv-as='labelConyugeOrConviviente'
                name='dniConyuge'
                maxlength='8'
                v-validate='"required|numeric|min:8|max:8"'
                :error='errors.first("form1.dniConyuge")'
                v-model='form.dniConyuge')
          .pt-4
            .row.botonxs
              .col-7.col-sm-7.order-2.text-left.px-1
                button.btn.btn-success.btn-md.btn-xs-block.font-weight-bold.claseboton
                  | {{ $t("components.navigation.submit") }}
                  font-awesome-icon.ml-3(
                    role='button'
                    focusable='false'
                    tabindex='-1'
                    icon='arrow-right')
              .col-5.col-sm-5.order-1.text-right
                button.btn.btn-plomo.btn-md.btn-xs-block.font-weight-bold.claseboton(
                  @click='goToBack'
                  type='button')
                  font-awesome-icon.mr-3(
                    role='button'
                    focusable='false'
                    tabindex='-1'
                    icon='arrow-left')
                  | {{ $t("components.navigation.return") }}
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import solicitudTypes from '@/store/types/solicitud';

import operadoresTelefoniaMovil from '@/data/operadoresTelefoniaMovil.json';

export default {
  name: 'DatosPersonales',
  dependencies: ['CommonService', 'SolicitudService'],
  data() {
    return {
      form: {
        numeroTelefonoMovil: null,
        operadorTelefoniaMovilId: null,
        correoElectronico: null,
        dniConyuge: null,
        hasConyuge: null,
        hasConviviente: null,
      },
      disabledDates: {
        from: new Date(),
      },
    };
  },
  computed: {
    operadoresTelefoniaMovil() {
      operadoresTelefoniaMovil.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return operadoresTelefoniaMovil.map((d) => ({ value: d.id, text: d.name }));
    },
    labelConyugeOrConviviente() {
      if (this.form.hasConyuge) return this.$t('datosPersonales.form1.dniConyuge.label');
      if (this.form.hasConviviente) return this.$t('datosPersonales.form1.dniConyuge.label');
      return '\u0020';
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    ...mapWaitingActions({
      goToBack: {
        action: solicitudTypes.actions.volver,
        loader: 'global',
      },
    }),
    async fetchData() {
      try {
        this.$wait.start('global');
        const data = await this.SolicitudService.getDatosPersonales();
        if (data) {
          this.CommonService.assign(this.form, data);
        }
      } catch (err) {
        this.$swal({ type: err.type, text: err.message });
      } finally {
        this.$wait.end('global');
      }
    },
    async onSubmit() {
      const isValid = await this.$validator.validateAll('form1');
      if (isValid) {
        try {
          this.$wait.start('global');
          const data = { ...this.form };
          delete data.hasConyuge;
          delete data.hasConviviente;
          const result = await this.SolicitudService.putDatosPersonales(data);
          this.$store.commit(solicitudTypes.mutations.setView, result.view);
        } catch (err) {
          this.$swal({ type: err.type, text: err.message });
        } finally {
          this.$wait.end('global');
        }
      }
    },
  },
};
</script>
<style>
@media (max-width:620px) {
  .containerxsPerso{
    margin-top: 280px;
    margin-bottom: 80px;
  }
}
</style>
