export default {
  es: {
    formHelp: {
      helpQuestionType: {
        label: 'Tipo de pregunta',
      },
      helpFullname: {
        label: 'Nombre completo',
      },
      helpPhone: {
        label: 'Tel\u00E9fono',
      },
      helpEmail: {
        label: 'Correo Electr\u00F3nico',
      },
      helpSubject: {
        label: 'Asunto',
      },
      helpMessage: {
        label: 'Mensaje',
      },
    },
    messages: {
      processing: 'No cierres la pantalla, estamos procesando tu solicitud',
      uploading: 'No cierres la pantalla, estamos procesando tu archivo',
      unauthorized: 'Acceso denegado',
      verified: 'Verificado',
      valid: 'V\u00E1lido',
      invalid: 'No v\u00E1lido',
      info: 'Informaci\u00F3n',
    },
    languages: {
      es: 'Espa\u00F1ol',
      en: 'Inglés',
    },
    components: {
      modal: {
        title: 'MAF',
        close: 'Cerrar',
        ok: 'OK',
      },
      navigation: {
        return: 'Regresar',
        submit: 'Guardar y Continuar',
        send: 'ENVIAR',
      },
      upload: {
        pending: 'Elegir archivo',
        uploaded: 'Listo',
      },
    },
    contactenos: {
      actions: {
        submit: 'Enviar',
      },
    },
    validacionInicial: {
      title: '¡Empecemos! Primero, verifica tus datos para continuar con la solicitud.',
      form1: {
        tipoProductoId: {
          label: '\u00BFCu\u00E1l es el destino del cr\u00E9dito a solicitar?',
          options: [
            {
              label: 'Comprar artefactos',
            },
            {
              label: 'Efectivo - Gastos personales',
            },
          ],
        },
        dni: {
          label: 'DNI',
          placeholder: 'Tu DNI',
        },
        estadoCivilId: {
          label: 'Estado Civil',
        },
        numeroTelefonoMovil: {
          info: 'Se enviar\u00E1 un c\u00F3digo de verificaci\u00F3n',
          label: 'Tel\u00E9fono celular',
          placeholder: 'Tu celular',
        },
        usoDeInformacionPersonal: {
          label: 'He leido y acepto las condiciones de tratamiento de mis datos personales',
          validateAs: '\u0020',
        },
        departamentoId: {
          label: 'Departamento',
        },
        provinciaId: {
          label: 'Provincia',
        },
        distritoId: {
          label: 'Distrito',
        },
        direccion: {
          label: 'Direcci\u00F3n',
        },
      },
      navigation: {
        submit: '\u00A1Desc\u00FAbrelo Aqu\u00ED!',
        next: 'Continuar',
        back: 'Regresar',
      },
    },
    bienvenida: {
      title: '\u00A1Bien {fullname}, has pasado la primera evaluaci\u00F3n, ya est\u00E1s m\u00E1s cerca de tu cr\u00E9dito!',
    },
    monto: {
      title: '\u00BFCu\u00E1nto dinero necesitas?',
      form1: {
        montoSolicitado: {
          info: 'Monto exacto',
          label: 'Monto de {from} a {to}',
          placeholder: 'Escribe el monto a solicitar',
          validateAs: 'Monto',
        },
      },
    },
    datosPersonales: {
      title: 'Valida tus datos ingresados, para continuar con la solicitud de cr\u00E9dito',
      form1: {
        fechaNacimiento: {
          label: 'Fecha de nacimiento',
          placeholder: 'YYYY-MM-DD',
        },
        sexo: {
          label: 'G\u00E9nero',
          options: [
            {
              label: 'Hombre',
            },
            {
              label: 'Mujer',
            },
          ],
        },
        digitoVerificador: {
          info: 'xxxx',
          label: 'D\u00EDgito Verificador',
          placeholder: 'Tu D\u00EDgito Verificador',
        },
        dniConyuge: {
          label: 'DNI c\u00F3nyuge',
          placeholder: 'DNI c\u00F3nyuge',
        },
        dniConviviente: {
          label: 'DNI conviviente',
          placeholder: 'DNI conviviente',
        },
        numeroTelefonoMovil: {
          label: 'N\u00FAmero de celular',
          placeholder: 'N\u00FAmero de celular',
          info: 'Se enviar\u00E1 un c\u00F3digo de verificaci\u00F3n',
        },
        operadorTelefoniaMovilId: {
          label: 'Operador',
        },
        correoElectronico: {
          label: 'Correo electr\u00F3nico',
          placeholder: 'Tu Correo electr\u00F3nico',
        },
      },
    },
    direccion1: {
      form1: {
        tipoViviendaId: {
          label: 'Tipo de vivienda',
        },
        resideDesde: {
          label: 'Reside desde',
          placeholder: 'YYYY-MM-DD',
        },
        direccion: {
          label: 'Direcci\u00F3n',
          placeholder: 'Tu direcci\u00F3n actual',
        },
      },
    },
    informacionCotizacion: {
      title: 'Informaci\u00F3n de la cotizaci\u00F3n',
      label: {
        modelo: 'MODELO / VERSI\u00d3N',
        version: 'Versi\u00F3n',
        modelPrice: 'VALOR DEL VEH\u00cdCULO *',
        initialFee: 'CUOTA INICIAL *',
        toyotaValue: 'TOYOTA VALUE',
        finalFee: 'CUOTA FINAL *',
        deadline: 'PLAZO',
        referenceText: '* Monto Referencial, no incluye seguro y est\u00E1 sujeto a la calificaci\u00F3n crediticia',
      },
      form1: {
        locationId: {
          label: 'Ubicaci\u00F3n',
        },
        concessionaireId: {
          label: 'Concesionario',
        },
        currencyOfFinancing: {
          label: 'Moneda de Financiamiento',
        },
        departamentoId: {
          label: 'Departamento de uso',
        },
        referenciaDomicilio: {
          label: 'Referencia del domicilio',
          placeholder: 'Referencia de tu direcci\u00F3n',
        },
        goingToUseInDepartment: {
          label: 'El veh\u00EDculo ser\u00E1 usado en el departamento donde resides',
          options: [
            {
              label: 'S\u00ED',
            },
            {
              label: 'No',
            },
          ],
        },
      },
    },
    visualizacionModelo: {
      hello: 'Hola ',
      subtitle: 'Ya est\u00E1s a unos pasos de tener el auto que siempre so\u00F1aste',
    },
    datosLaborales: {
      form1: {
        rucEmpleadorSolicitante: {
          label: 'RUC trabajo actual',
          label2: 'RUC personal',
          placeholder: 'RUC',
        },
        fechaIngreso: {
          label: 'Fecha de inicio de actividades',
          placeholder: 'YYYY-MM-DD',
        },
        incomeSource: {
          label: 'Principal fuente de ingreso',
          placeholder: 'Principal fuente de ingreso',
          ghost: 'Profesional Independiente(4 Categoria) \n Profesionales que desarollaron individualmente \n una profesión y que son capaces de generar \n ingresos como consecuencia de la prestación \n  de sus servicios. \n',
          ghost2: 'Profesional Dependiente(5 categoria) \n Trabajador que tiene una relación de \n dependencia con una empresa, mediante \n  contratos determinados o indeterminado',
        },
        tipoIngreso: {
          label: 'Tipo de ingreso',
          placeholder: 'Tipo de ingreso',
          ghost: 'Tipo de ingreso',
        },
        incomes: {
          label: 'Ingreso neto mensual',
          placeholder: 'S/',
        },
        incomes1: {
          label: 'Ingreso neto mensual - Mes anterior',
          placeholder: 'S/',
        },
        incomes2: {
          label: 'Ingreso neto mensual - 2do Mes anterior',
          placeholder: 'S/',
        },
        fechaIngresoAnterior: {
          label: 'Fecha de inicio (anterior centro laboral)',
          placeholder: 'YYYY-MM-DD',
        },
        ingresoAnterior: {
          label: 'Ingreso neto mensual (anterior centro laboral)',
          placeholder: 'S/',
        },
      },
    },
    visitaConcesionario: {
      title: 'Para terminar, valida el concesionario y la fecha de visita para mostrarte las bondades de tu auto',
      form1: {
        concesionarioVisita: {
          label: 'Concesionario / Sucursal',
          placeholder: 'Seleccione Concesionario',
        },
        horaVisita: {
          label: 'Hora de visita',
          placeholder: 'HH:MM',
        },
        fechaVisita: {
          label: 'Fecha de visita a concesionario',
          placeholder: 'YYYY-MM-DD',
        },
      },
    },
    ingresos: {
      form1: {
        tipoIngresoId: {
          label: 'Tipo de ingreso',
          options: [
            {
              label: 'Fijo',
            },
            {
              label: 'Variable',
            },
          ],
        },
        ingresoBrutoMensual1: {
          label: 'Ingreso bruto mensual 1',
          placeholder: 'S/',
        },
        ingresoBrutoMensual2: {
          label: 'Ingreso bruto mensual 2',
          placeholder: 'S/',
        },
        ingresoBrutoMensual3: {
          label: 'Ingreso bruto mensual 3',
          placeholder: 'S/',
        },
      },
    },
    dictamen: {
      titleA: '\u00A1Felicidades tienes una l\u00EDnea de cr\u00E9dito aprobada para compra de artefactos por! {montoCuota}',
      titleE: '\u00A1Felicitaciones Omar!',
      titleP: '\u00A1Felicitaciones Omar!',
      subtitleP: 'Tienes tu cr\u00E9dito vehicular pre aprobado!',
      resumen1: 'Cuota mensual de: {montoCuota}',
      resumen2: '{numeroCuotas} meses (TCEA de {tea}%)',
      helper: '(*) Ver condiciones',
      navigation: {
        submit: 'Aceptar',
      },
    },
    proforma: {
      titleP: '\u00A1Felicitaciones Omar!',
      subtitle: 'Descarga la proforma de tu cr\u00E9dito!',
      navigation: {
        submit: 'Continuar',
      },
    },
    pin: {
      thanks: '¡Gracias!',
      title: 'Continuemos, te falta muy poco para completar tu evaluaci\u00F3n.\n En breve te enviaremos un mensaje SMS con un c\u00F3digo de seguridad de 4 d\u00EDgitos',
      form1: {
        d1: {
          placeholder: '',
        },
        d2: {
          placeholder: '',
        },
        d3: {
          placeholder: '',
        },
        d4: {
          placeholder: '',
        },
      },
      notice1: 'Ingresa el c\u00F3digo de 4 d\u00EDgitos',
      notice2: '\u00BFNo recibiste el c\u00F3digo?',
      notice3: '¡Solicita uno nuevo aquí!',
    },
    preDocumentos: {
      form1: {
        adjuntarDocumentos: {
          label: '\u00BFDeseas adjuntar tus documentos o entregarlos en tienda?',
          options: [
            {
              label: 'Adjuntar',
            },
            {
              label: 'En tienda',
              info1: 'Recuerda que debes llevar los siguientes documentos: Copia del DNI y Copia de recibo de servicio.',
              info2: 'Recuerda que debes llevar los siguientes documentos: Copia del DNI, Copia de recibo de servicio y sustento de ingresos.',
            },
          ],
        },
      },
      navigation: {
        submit1: 'Siguiente',
        submit2: 'Finalizar',
      },
    },
    documentos: {
      title: 'Adjunta los siguientes documentos en los formatos: PNG, JPG, JPEG o PDF.',
      notice1: 'Tama\u00F1o m\u00E1ximo por archivo es de 5 MB.',
      notice2: 'Aseg\u00FArate de subir los documentos exactos y legibles, puesto que ser\u00E1n validados.',
      archivos: [
        { label: 'DNI anverso' },
        { label: 'DNI reverso' },
        { label: 'Recibo de servicios' },
        { label: 'Boleta de pago 1', otherwise: 'Recibo por honorario 1' },
        { label: 'Boleta de pago 2', otherwise: 'Recibo por honorario 2' },
        { label: 'Boleta de pago 3', otherwise: 'Recibo por honorario 3' },
      ],
      navigation: {
        submit: 'Finalizar',
      },
    },
    fin: {
      title: '{fullname}, has completado de forma satisfactoria tu expediente,',
      subtitleA: 'Te enviaremos un e-mail con las instrucciones para que realices tus compras',
      subtitleE: 'Te enviaremos un e-mail con el resultado de la evaluaci\u00F3n',
      navigation: {
        submit: 'Aceptar',
      },
    },
  },
};
